import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import PackageItemWithDescription from './PackageItemWithDescription';

const useStyles = makeStyles(theme => ({
  container: { 
    paddingTop: 32,
    paddingBottom: 32,
    width: '100%',
    minHeight: 300,
    backgroundColor: settings => settings.backgroundColor,
  },
  itemGrid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "70px 0px",
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gap: "110px 0px",
    },
  }
}));

export default function PackageDescriptions({ backgroundColor, packageData}) {
  
  const settings = {
    backgroundColor: backgroundColor
  }
  const classes = useStyles(settings);
  
  const itemsWithDescriptions = packageData.items.map(itemData => {
    return (
      <PackageItemWithDescription
        key={itemData.title}
        icon={itemData.icon}
        title={itemData.title}
        description={itemData.description}
      />
    )
  })

  return (
    <div className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '90%',
          top: 100,
          bottom: 120,
        }}
        mobileSizes={{
          width: '90%',
          top: 32,
          bottom: 70,
        }}>
          <div className={classes.itemGrid}>
            {itemsWithDescriptions}
          </div>
        </ResponsiveMarginContainer>
    </div>
  );
}
