import React from 'react';
import { mapPackage } from '../packages/mapPackages';
import PackageDescriptions from './PackageDescriptions';

export default function PackageDescriptionsSlice({ sliceData }) {
  
  const primary = sliceData.primary;
  const backgroundColor = primary.background_color;
  const packageData = mapPackage(primary.package.document.data);

  return (
    <PackageDescriptions backgroundColor={backgroundColor} packageData={packageData}/>
  );
}