import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const lineHeight = 100;
const borderWidth = 3;
const nrOfRows = 3;

const useStyles = makeStyles(theme => ({
  inputField: {
    height: (lineHeight + borderWidth) * nrOfRows,
    paddingTop: 30,
    width: '100%',
    background: 'transparent',
    border: 'none',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    letterSpacing: theme.typography.h4.letterSpacing,
    lineHeight: `${lineHeight}px`,
    //lines
    backgroundImage: `
      repeating-linear-gradient(transparent, transparent ${lineHeight}px, black ${lineHeight}px, black ${lineHeight+borderWidth}px)`,

    overflow: 'hidden',
    resize: 'none',
    '&::placeholder': {
      paddingTop: 20,
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
      letterSpacing: theme.typography.h2.letterSpacing,
      lineHeight: theme.typography.h2.lineHeight,
      color: 'black',
      opacity: 2,
    },
    '&:focus': {
      outline: 0,
    },
  },}));


export default function TextAreaInputField({ placeholder, onChange, value }) {
  const classes = useStyles();
  return (
    <div>
      <textarea aria-label={placeholder} value={value} onChange={onChange} type="text" className={classes.inputField} placeholder={placeholder}>
      </textarea>
    </div>
  );
}