import React from 'react';
import InfoCards from './InfoCards';

export default function InfoCardsSlice({ sliceData }) {
  return (
    <InfoCards
        title={sliceData.primary.title}
        maxWidth={sliceData.primary.card_max_width}
        desktopHeight={sliceData.primary.card_desktop_height}
        mobileHeight={sliceData.primary.card_mobile_height}
        infoCards={sliceData.items}
    />
  );
}
