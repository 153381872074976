import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from "framer-motion";
import SnabbaSvg from '../icons/snabbaSvg';
import SakraSvg from '../icons/sakraSvg';
import SnyggaSvg from '../icons/snyggaSvg';
import SynligaSvg from '../icons/synligaSvg';

const useStyles = makeStyles(theme => ({
  container: {
    height: 102,
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: 12,
    backgroundColor: theme.palette.primaryYellow.main,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 64,
    }
  },
  part: {
    height: 56,
    display: 'inline-block',
  },
}));

const spacing = 50;
const delay = 4;

const widths = {
  snabba: 330 + spacing,
  sakra: 270 + spacing,
  snygga: 320 + spacing,
  synliga: 336 + spacing,
}

const variants = {
  move: {
    x: -widths.snabba,
    transition: {
      when: 'beforeChildren',
      delay: 1,
    },
  }
}

const move2 = { move: { x: -widths.snygga, transition: { delay: delay }}}
const move3 = { move: { x: -widths.sakra, transition: { delay: 2 * delay } }}
const move4 = { move: { x: -widths.synliga, transition: { delay: 3 * delay } }}
const move5 = { move: { x: widths.synliga + widths.sakra, transition: { delay: 5 * delay } }}
const move6 = { move: { x: widths.snygga, transition: { delay: 6 * delay } }}
const move65 = { move: { x: widths.snabba, transition: { delay: 6.5 * delay } }}
const move7 = { move: { x: -widths.snabba, transition: { delay: 7 * delay } }}
const move8 = { move: { x: -widths.snygga - widths.sakra - widths.synliga, transition: { delay: 8 * delay } }}
const move9 = { move: { x: widths.synliga + widths.sakra, transition: { delay: 9 * delay } }}
const move10 = { move: { x: - widths.sakra - widths.synliga, transition: { delay: 10 * delay } }}
const move11 = { move: { x: widths.synliga + widths.sakra + widths.snygga, transition: { delay: 11 * delay } }}
const move12 = { move: { x: widths.snabba, transition: { delay: 12 * delay } }}

export default function TextBanderoll() {
  const classes = useStyles();

  const size = "large";

  return (
    <div className={classes.container}>
      <motion.div animate="move" variants={variants}>
        <motion.div variants={move2} >
        <motion.div variants={move3} >
        <motion.div variants={move4} >
        <motion.div variants={move5} >
        <motion.div variants={move6} >
        <motion.div variants={move65} >
        <motion.div variants={move7} >
        <motion.div variants={move8} >
        <motion.div variants={move9} >
        <motion.div variants={move10} >
        <motion.div variants={move11} > 
        <motion.div variants={move12} > 
          <div 
            style={{ width: widths.synliga, marginLeft: -widths.synliga }} 
            className={classes.part}>
            <SynligaSvg size={size} />
          </div>
          <div style={{ width: widths.snabba }} className={classes.part}>
            <SnabbaSvg size={size} />
          </div>
          <div style={{ width: widths.snygga }} className={classes.part}>
            <SnyggaSvg size={size} />
          </div>
          <div style={{ width: widths.sakra }} className={classes.part}>
            <SakraSvg size={size} />
          </div>
          <div style={{ width: widths.synliga }} className={classes.part}>
            <SynligaSvg size={size} />
          </div>
          <div style={{ width: widths.snabba }} className={classes.part}>
            <SnabbaSvg size={size} />
          </div>
          <div style={{ width: widths.snygga }} className={classes.part}>
            <SnyggaSvg size={size} />
          </div>
          <div style={{ width: widths.sakra }} className={classes.part}>
            <SakraSvg size={size} />
          </div>
          <div style={{ width: widths.synliga }} className={classes.part}>
            <SynligaSvg size={size} />
          </div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
}