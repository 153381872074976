import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SkillBar from '../stuff/SkillBar';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 32,
    paddingLeft: 16,
    paddingRight: 32,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 48,
      paddingRight: 92,
    },
  },
  skillzContainer: {
    marginTop: 32,
  },
  skillbar: {
    marginBottom: 16,
  }
}));

export default function CardContentSkillz({ htmlText, skillz }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
        <HtmlTextRenderer>
          {htmlText}
        </HtmlTextRenderer>
      <div className={classes.skillzContainer}>
        {skillz.map(skill => {
          return (
            <div key={skill.title} className={classes.skillbar}>
              <SkillBar title={skill.title} level={skill.level} />
            </div>
          )
        })}
      </div>
    </div>
  );
}