import React from 'react';
import BlogSection from '../blog-section/BlogSection';
import TextArea from './TextArea';

export default function TextAreaSlice({ sliceData }) {
  return (
    <BlogSection>
      <TextArea 
        htmlText={sliceData.primary.text.html}
        headline={sliceData.primary.headline}
        numberOfColumns={sliceData.primary.two_columns === true? 2 : 1}
      />
    </BlogSection>
  );
}
