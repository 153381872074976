import React from 'react';

export default function ContactWhiteOutlinedSvg() {
  return (
    <svg width="593" height="63" viewBox="0 0 593 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="contact-outline-mask" maskUnits="userSpaceOnUse" x="0" y="0" width="593" height="63" fill="black">
        <rect fill="white" width="593" height="63" />
        <path d="M4.97293 59.2083C4.44682 59.2083 3.97331 59.0236 3.55242 58.6542C3.18414 58.2319 3 57.7569 3 57.2292V5.85C3 5.26944 3.18414 4.79444 3.55242 4.425C3.9207 4.00278 4.39421 3.79166 4.97293 3.79166H15.5479C16.1266 3.79166 16.6001 4.00278 16.9684 4.425C17.3366 4.79444 17.5208 5.26944 17.5208 5.85V23.1083L31.4892 5.375C32.0679 4.31944 33.0938 3.79166 34.5669 3.79166H46.6413C47.0622 3.79166 47.4305 3.97639 47.7461 4.34584C48.1144 4.6625 48.2986 5.03194 48.2986 5.45417C48.2986 5.87639 48.1933 6.19306 47.9829 6.40416L29.5951 30.3125L49.4823 56.5958C49.6928 56.8069 49.798 57.1236 49.798 57.5458C49.798 57.9681 49.6138 58.3639 49.2456 58.7333C48.9299 59.05 48.5353 59.2083 48.0618 59.2083H35.6718C34.83 59.2083 34.146 59.05 33.6199 58.7333C33.0938 58.3639 32.7255 57.9944 32.5151 57.625L17.5208 38.3083V57.2292C17.5208 57.7569 17.3366 58.2319 16.9684 58.6542C16.6001 59.0236 16.1266 59.2083 15.5479 59.2083H4.97293Z" />
        <path d="M75.8259 60C68.3551 60 62.4626 58.1792 58.1484 54.5375C53.8343 50.8958 51.5457 45.5389 51.2826 38.4667C51.23 36.9889 51.2037 34.7194 51.2037 31.6583C51.2037 28.5972 51.23 26.3014 51.2826 24.7708C51.4931 17.8042 53.7817 12.4472 58.1484 8.7C62.5678 4.9 68.4603 3 75.8259 3C83.1389 3 88.9788 4.9 93.3456 8.7C97.7649 12.4472 100.08 17.8042 100.29 24.7708C100.396 27.8319 100.448 30.1278 100.448 31.6583C100.448 33.2417 100.396 35.5111 100.29 38.4667C100.027 45.5389 97.7386 50.8958 93.4245 54.5375C89.1629 58.1792 83.2967 60 75.8259 60ZM75.8259 48.125C78.5617 48.125 80.7451 47.3069 82.376 45.6708C84.007 43.9819 84.8751 41.4222 84.9803 37.9917C85.0855 34.9306 85.1382 32.7403 85.1382 31.4208C85.1382 30.1014 85.0855 27.9639 84.9803 25.0083C84.8751 21.5778 84.007 19.0444 82.376 17.4083C80.7451 15.7194 78.5617 14.875 75.8259 14.875C73.0375 14.875 70.8278 15.7194 69.1969 17.4083C67.5659 19.0444 66.6978 21.5778 66.5926 25.0083C66.54 26.4861 66.5137 28.6236 66.5137 31.4208C66.5137 34.2708 66.54 36.4611 66.5926 37.9917C66.6978 41.4222 67.5659 43.9819 69.1969 45.6708C70.8278 47.3069 73.0375 48.125 75.8259 48.125Z" />
        <path d="M111.326 59.2083C110.8 59.2083 110.327 59.0236 109.906 58.6542C109.538 58.2319 109.353 57.7569 109.353 57.2292V5.85C109.353 5.26944 109.538 4.79444 109.906 4.425C110.274 4.00278 110.748 3.79166 111.326 3.79166H120.244C121.454 3.79166 122.348 4.29305 122.927 5.29583L141.552 34.825V5.85C141.552 5.26944 141.736 4.79444 142.104 4.425C142.472 4.00278 142.946 3.79166 143.525 3.79166H153.547C154.126 3.79166 154.599 4.00278 154.968 4.425C155.336 4.79444 155.52 5.26944 155.52 5.85V57.15C155.52 57.7306 155.336 58.2319 154.968 58.6542C154.599 59.0236 154.126 59.2083 153.547 59.2083H144.629C143.419 59.2083 142.525 58.7069 141.946 57.7042L123.401 29.3625V57.2292C123.401 57.8097 123.19 58.2847 122.769 58.6542C122.401 59.0236 121.928 59.2083 121.349 59.2083H111.326Z" />
        <path d="M180.435 59.2083C179.856 59.2083 179.356 59.0236 178.935 58.6542C178.567 58.2847 178.383 57.8097 178.383 57.2292V16.6958H164.493C163.915 16.6958 163.415 16.5111 162.994 16.1417C162.626 15.7722 162.441 15.2972 162.441 14.7167V5.85C162.441 5.26944 162.626 4.79444 162.994 4.425C163.415 4.00278 163.915 3.79166 164.493 3.79166H207.345C207.924 3.79166 208.398 4.00278 208.766 4.425C209.187 4.79444 209.397 5.26944 209.397 5.85V14.7167C209.397 15.2972 209.187 15.7722 208.766 16.1417C208.398 16.5111 207.924 16.6958 207.345 16.6958H193.456V57.2292C193.456 57.8097 193.245 58.2847 192.825 58.6542C192.456 59.0236 191.983 59.2083 191.404 59.2083H180.435Z" />
        <path d="M207.62 59.2083C207.199 59.2083 206.804 59.05 206.436 58.7333C206.12 58.3639 205.962 57.9681 205.962 57.5458C205.962 57.2292 205.989 56.9917 206.041 56.8333L224.429 6.0875C224.955 4.55694 226.007 3.79166 227.586 3.79166H239.897C241.475 3.79166 242.527 4.55694 243.054 6.0875L261.441 56.8333C261.494 56.9917 261.52 57.2292 261.52 57.5458C261.52 57.9681 261.336 58.3639 260.968 58.7333C260.652 59.05 260.284 59.2083 259.863 59.2083H249.604C248.341 59.2083 247.447 58.6278 246.921 57.4667L244.08 49.7875H223.403L220.562 57.4667C220.036 58.6278 219.142 59.2083 217.879 59.2083H207.62ZM226.718 37.9125H240.765L233.741 17.725L226.718 37.9125Z" />
        <path d="M269.469 59.2083C268.943 59.2083 268.47 59.0236 268.049 58.6542C267.681 58.2319 267.496 57.7569 267.496 57.2292V5.85C267.496 5.26944 267.681 4.79444 268.049 4.425C268.417 4.00278 268.891 3.79166 269.469 3.79166H280.044C280.623 3.79166 281.096 4.00278 281.465 4.425C281.833 4.79444 282.017 5.26944 282.017 5.85V23.1083L295.986 5.375C296.564 4.31944 297.59 3.79166 299.063 3.79166H311.138C311.559 3.79166 311.927 3.97639 312.243 4.34584C312.611 4.6625 312.795 5.03194 312.795 5.45417C312.795 5.87639 312.69 6.19306 312.479 6.40416L294.092 30.3125L313.979 56.5958C314.189 56.8069 314.294 57.1236 314.294 57.5458C314.294 57.9681 314.11 58.3639 313.742 58.7333C313.426 59.05 313.032 59.2083 312.558 59.2083H300.168C299.326 59.2083 298.642 59.05 298.116 58.7333C297.59 58.3639 297.222 57.9944 297.011 57.625L282.017 38.3083V57.2292C282.017 57.7569 281.833 58.2319 281.465 58.6542C281.096 59.0236 280.623 59.2083 280.044 59.2083H269.469Z" />
        <path d="M334.416 59.2083C333.837 59.2083 333.337 59.0236 332.916 58.6542C332.548 58.2847 332.364 57.8097 332.364 57.2292V16.6958H318.475C317.896 16.6958 317.396 16.5111 316.975 16.1417C316.607 15.7722 316.423 15.2972 316.423 14.7167V5.85C316.423 5.26944 316.607 4.79444 316.975 4.425C317.396 4.00278 317.896 3.79166 318.475 3.79166H361.327C361.905 3.79166 362.379 4.00278 362.747 4.425C363.168 4.79444 363.378 5.26944 363.378 5.85V14.7167C363.378 15.2972 363.168 15.7722 362.747 16.1417C362.379 16.5111 361.905 16.6958 361.327 16.6958H347.437V57.2292C347.437 57.8097 347.227 58.2847 346.806 58.6542C346.438 59.0236 345.964 59.2083 345.385 59.2083H334.416Z" />
        <path d="M361.601 59.2083C361.18 59.2083 360.786 59.05 360.417 58.7333C360.102 58.3639 359.944 57.9681 359.944 57.5458C359.944 57.2292 359.97 56.9917 360.023 56.8333L378.41 6.0875C378.936 4.55694 379.989 3.79166 381.567 3.79166H393.878C395.457 3.79166 396.509 4.55694 397.035 6.0875L415.423 56.8333C415.475 56.9917 415.502 57.2292 415.502 57.5458C415.502 57.9681 415.317 58.3639 414.949 58.7333C414.633 59.05 414.265 59.2083 413.844 59.2083H403.585C402.322 59.2083 401.428 58.6278 400.902 57.4667L398.061 49.7875H377.384L374.543 57.4667C374.017 58.6278 373.123 59.2083 371.86 59.2083H361.601ZM380.699 37.9125H394.746L387.723 17.725L380.699 37.9125Z" />
        <path d="M458.929 60C451.459 60 445.566 58.1792 441.252 54.5375C436.938 50.8958 434.649 45.5389 434.386 38.4667C434.334 36.9889 434.307 34.7194 434.307 31.6583C434.307 28.5972 434.334 26.3014 434.386 24.7708C434.597 17.8042 436.885 12.4472 441.252 8.7C445.671 4.9 451.564 3 458.929 3C466.243 3 472.082 4.9 476.449 8.7C480.869 12.4472 483.183 17.8042 483.394 24.7708C483.499 27.8319 483.552 30.1278 483.552 31.6583C483.552 33.2417 483.499 35.5111 483.394 38.4667C483.131 45.5389 480.842 50.8958 476.528 54.5375C472.267 58.1792 466.4 60 458.929 60ZM458.929 48.125C461.665 48.125 463.849 47.3069 465.48 45.6708C467.111 43.9819 467.979 41.4222 468.084 37.9917C468.189 34.9306 468.242 32.7403 468.242 31.4208C468.242 30.1014 468.189 27.9639 468.084 25.0083C467.979 21.5778 467.111 19.0444 465.48 17.4083C463.849 15.7194 461.665 14.875 458.929 14.875C456.141 14.875 453.931 15.7194 452.3 17.4083C450.669 19.0444 449.801 21.5778 449.696 25.0083C449.644 26.4861 449.617 28.6236 449.617 31.4208C449.617 34.2708 449.644 36.4611 449.696 37.9917C449.801 41.4222 450.669 43.9819 452.3 45.6708C453.931 47.3069 456.141 48.125 458.929 48.125Z" />
        <path d="M513.37 60C508.267 60 503.926 59.2611 500.349 57.7833C496.771 56.3056 494.062 54.3528 492.22 51.925C490.379 49.4972 489.406 46.8583 489.3 44.0083C489.3 43.5333 489.458 43.1375 489.774 42.8208C490.142 42.5042 490.563 42.3458 491.037 42.3458H501.533C502.164 42.3458 502.664 42.4514 503.032 42.6625C503.453 42.8736 503.9 43.2431 504.374 43.7708C505.058 45.0375 506.11 46.0931 507.53 46.9375C509.003 47.7292 510.95 48.125 513.37 48.125C516.211 48.125 518.395 47.7028 519.92 46.8583C521.446 45.9611 522.209 44.7472 522.209 43.2167C522.209 42.1083 521.814 41.2111 521.025 40.525C520.236 39.7861 518.973 39.1528 517.237 38.625C515.501 38.0444 513.002 37.4111 509.74 36.725C503.374 35.4583 498.613 33.5319 495.456 30.9458C492.299 28.3597 490.721 24.6653 490.721 19.8625C490.721 16.5903 491.615 13.6875 493.404 11.1542C495.245 8.56806 497.823 6.5625 501.138 5.1375C504.505 3.7125 508.398 3 512.818 3C517.448 3 521.446 3.81806 524.813 5.45417C528.233 7.0375 530.837 9.04306 532.626 11.4708C534.467 13.8458 535.441 16.1944 535.546 18.5167C535.546 18.9917 535.388 19.4139 535.072 19.7833C534.757 20.1 534.362 20.2583 533.889 20.2583H522.84C521.683 20.2583 520.788 19.7833 520.157 18.8333C519.841 17.725 519.026 16.8014 517.711 16.0625C516.395 15.2708 514.764 14.875 512.818 14.875C510.608 14.875 508.898 15.2708 507.688 16.0625C506.478 16.8542 505.873 18.0153 505.873 19.5458C505.873 21.0764 506.662 22.2903 508.241 23.1875C509.819 24.0319 512.765 24.9028 517.079 25.8C522.025 26.6972 525.944 27.8319 528.838 29.2042C531.784 30.5236 533.941 32.2653 535.309 34.4292C536.73 36.5403 537.44 39.2319 537.44 42.5042C537.44 46.0931 536.414 49.2069 534.362 51.8458C532.31 54.4847 529.469 56.5167 525.839 57.9417C522.209 59.3139 518.053 60 513.37 60Z" />
        <path d="M565.93 60C560.827 60 556.486 59.2611 552.909 57.7833C549.331 56.3056 546.622 54.3528 544.78 51.925C542.939 49.4972 541.966 46.8583 541.86 44.0083C541.86 43.5333 542.018 43.1375 542.334 42.8208C542.702 42.5042 543.123 42.3458 543.597 42.3458H554.093C554.724 42.3458 555.224 42.4514 555.592 42.6625C556.013 42.8736 556.46 43.2431 556.934 43.7708C557.618 45.0375 558.67 46.0931 560.09 46.9375C561.564 47.7292 563.51 48.125 565.93 48.125C568.771 48.125 570.955 47.7028 572.48 46.8583C574.006 45.9611 574.769 44.7472 574.769 43.2167C574.769 42.1083 574.374 41.2111 573.585 40.525C572.796 39.7861 571.533 39.1528 569.797 38.625C568.061 38.0444 565.562 37.4111 562.3 36.725C555.934 35.4583 551.173 33.5319 548.016 30.9458C544.859 28.3597 543.281 24.6653 543.281 19.8625C543.281 16.5903 544.175 13.6875 545.964 11.1542C547.806 8.56806 550.384 6.5625 553.698 5.1375C557.065 3.7125 560.958 3 565.378 3C570.008 3 574.006 3.81806 577.373 5.45417C580.793 7.0375 583.397 9.04306 585.186 11.4708C587.027 13.8458 588.001 16.1944 588.106 18.5167C588.106 18.9917 587.948 19.4139 587.633 19.7833C587.317 20.1 586.922 20.2583 586.449 20.2583H575.4C574.243 20.2583 573.348 19.7833 572.717 18.8333C572.401 17.725 571.586 16.8014 570.271 16.0625C568.955 15.2708 567.324 14.875 565.378 14.875C563.168 14.875 561.458 15.2708 560.248 16.0625C559.038 16.8542 558.433 18.0153 558.433 19.5458C558.433 21.0764 559.222 22.2903 560.801 23.1875C562.379 24.0319 565.325 24.9028 569.639 25.8C574.585 26.6972 578.504 27.8319 581.398 29.2042C584.344 30.5236 586.501 32.2653 587.869 34.4292C589.29 36.5403 590 39.2319 590 42.5042C590 46.0931 588.974 49.2069 586.922 51.8458C584.87 54.4847 582.029 56.5167 578.399 57.9417C574.769 59.3139 570.613 60 565.93 60Z" />
      </mask>
      <path d="M4.97293 59.2083C4.44682 59.2083 3.97331 59.0236 3.55242 58.6542C3.18414 58.2319 3 57.7569 3 57.2292V5.85C3 5.26944 3.18414 4.79444 3.55242 4.425C3.9207 4.00278 4.39421 3.79166 4.97293 3.79166H15.5479C16.1266 3.79166 16.6001 4.00278 16.9684 4.425C17.3366 4.79444 17.5208 5.26944 17.5208 5.85V23.1083L31.4892 5.375C32.0679 4.31944 33.0938 3.79166 34.5669 3.79166H46.6413C47.0622 3.79166 47.4305 3.97639 47.7461 4.34584C48.1144 4.6625 48.2986 5.03194 48.2986 5.45417C48.2986 5.87639 48.1933 6.19306 47.9829 6.40416L29.5951 30.3125L49.4823 56.5958C49.6928 56.8069 49.798 57.1236 49.798 57.5458C49.798 57.9681 49.6138 58.3639 49.2456 58.7333C48.9299 59.05 48.5353 59.2083 48.0618 59.2083H35.6718C34.83 59.2083 34.146 59.05 33.6199 58.7333C33.0938 58.3639 32.7255 57.9944 32.5151 57.625L17.5208 38.3083V57.2292C17.5208 57.7569 17.3366 58.2319 16.9684 58.6542C16.6001 59.0236 16.1266 59.2083 15.5479 59.2083H4.97293Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M75.8259 60C68.3551 60 62.4626 58.1792 58.1484 54.5375C53.8343 50.8958 51.5457 45.5389 51.2826 38.4667C51.23 36.9889 51.2037 34.7194 51.2037 31.6583C51.2037 28.5972 51.23 26.3014 51.2826 24.7708C51.4931 17.8042 53.7817 12.4472 58.1484 8.7C62.5678 4.9 68.4603 3 75.8259 3C83.1389 3 88.9788 4.9 93.3456 8.7C97.7649 12.4472 100.08 17.8042 100.29 24.7708C100.396 27.8319 100.448 30.1278 100.448 31.6583C100.448 33.2417 100.396 35.5111 100.29 38.4667C100.027 45.5389 97.7386 50.8958 93.4245 54.5375C89.1629 58.1792 83.2967 60 75.8259 60ZM75.8259 48.125C78.5617 48.125 80.7451 47.3069 82.376 45.6708C84.007 43.9819 84.8751 41.4222 84.9803 37.9917C85.0855 34.9306 85.1382 32.7403 85.1382 31.4208C85.1382 30.1014 85.0855 27.9639 84.9803 25.0083C84.8751 21.5778 84.007 19.0444 82.376 17.4083C80.7451 15.7194 78.5617 14.875 75.8259 14.875C73.0375 14.875 70.8278 15.7194 69.1969 17.4083C67.5659 19.0444 66.6978 21.5778 66.5926 25.0083C66.54 26.4861 66.5137 28.6236 66.5137 31.4208C66.5137 34.2708 66.54 36.4611 66.5926 37.9917C66.6978 41.4222 67.5659 43.9819 69.1969 45.6708C70.8278 47.3069 73.0375 48.125 75.8259 48.125Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M111.326 59.2083C110.8 59.2083 110.327 59.0236 109.906 58.6542C109.538 58.2319 109.353 57.7569 109.353 57.2292V5.85C109.353 5.26944 109.538 4.79444 109.906 4.425C110.274 4.00278 110.748 3.79166 111.326 3.79166H120.244C121.454 3.79166 122.348 4.29305 122.927 5.29583L141.552 34.825V5.85C141.552 5.26944 141.736 4.79444 142.104 4.425C142.472 4.00278 142.946 3.79166 143.525 3.79166H153.547C154.126 3.79166 154.599 4.00278 154.968 4.425C155.336 4.79444 155.52 5.26944 155.52 5.85V57.15C155.52 57.7306 155.336 58.2319 154.968 58.6542C154.599 59.0236 154.126 59.2083 153.547 59.2083H144.629C143.419 59.2083 142.525 58.7069 141.946 57.7042L123.401 29.3625V57.2292C123.401 57.8097 123.19 58.2847 122.769 58.6542C122.401 59.0236 121.928 59.2083 121.349 59.2083H111.326Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M180.435 59.2083C179.856 59.2083 179.356 59.0236 178.935 58.6542C178.567 58.2847 178.383 57.8097 178.383 57.2292V16.6958H164.493C163.915 16.6958 163.415 16.5111 162.994 16.1417C162.626 15.7722 162.441 15.2972 162.441 14.7167V5.85C162.441 5.26944 162.626 4.79444 162.994 4.425C163.415 4.00278 163.915 3.79166 164.493 3.79166H207.345C207.924 3.79166 208.398 4.00278 208.766 4.425C209.187 4.79444 209.397 5.26944 209.397 5.85V14.7167C209.397 15.2972 209.187 15.7722 208.766 16.1417C208.398 16.5111 207.924 16.6958 207.345 16.6958H193.456V57.2292C193.456 57.8097 193.245 58.2847 192.825 58.6542C192.456 59.0236 191.983 59.2083 191.404 59.2083H180.435Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M207.62 59.2083C207.199 59.2083 206.804 59.05 206.436 58.7333C206.12 58.3639 205.962 57.9681 205.962 57.5458C205.962 57.2292 205.989 56.9917 206.041 56.8333L224.429 6.0875C224.955 4.55694 226.007 3.79166 227.586 3.79166H239.897C241.475 3.79166 242.527 4.55694 243.054 6.0875L261.441 56.8333C261.494 56.9917 261.52 57.2292 261.52 57.5458C261.52 57.9681 261.336 58.3639 260.968 58.7333C260.652 59.05 260.284 59.2083 259.863 59.2083H249.604C248.341 59.2083 247.447 58.6278 246.921 57.4667L244.08 49.7875H223.403L220.562 57.4667C220.036 58.6278 219.142 59.2083 217.879 59.2083H207.62ZM226.718 37.9125H240.765L233.741 17.725L226.718 37.9125Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M269.469 59.2083C268.943 59.2083 268.47 59.0236 268.049 58.6542C267.681 58.2319 267.496 57.7569 267.496 57.2292V5.85C267.496 5.26944 267.681 4.79444 268.049 4.425C268.417 4.00278 268.891 3.79166 269.469 3.79166H280.044C280.623 3.79166 281.096 4.00278 281.465 4.425C281.833 4.79444 282.017 5.26944 282.017 5.85V23.1083L295.986 5.375C296.564 4.31944 297.59 3.79166 299.063 3.79166H311.138C311.559 3.79166 311.927 3.97639 312.243 4.34584C312.611 4.6625 312.795 5.03194 312.795 5.45417C312.795 5.87639 312.69 6.19306 312.479 6.40416L294.092 30.3125L313.979 56.5958C314.189 56.8069 314.294 57.1236 314.294 57.5458C314.294 57.9681 314.11 58.3639 313.742 58.7333C313.426 59.05 313.032 59.2083 312.558 59.2083H300.168C299.326 59.2083 298.642 59.05 298.116 58.7333C297.59 58.3639 297.222 57.9944 297.011 57.625L282.017 38.3083V57.2292C282.017 57.7569 281.833 58.2319 281.465 58.6542C281.096 59.0236 280.623 59.2083 280.044 59.2083H269.469Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M334.416 59.2083C333.837 59.2083 333.337 59.0236 332.916 58.6542C332.548 58.2847 332.364 57.8097 332.364 57.2292V16.6958H318.475C317.896 16.6958 317.396 16.5111 316.975 16.1417C316.607 15.7722 316.423 15.2972 316.423 14.7167V5.85C316.423 5.26944 316.607 4.79444 316.975 4.425C317.396 4.00278 317.896 3.79166 318.475 3.79166H361.327C361.905 3.79166 362.379 4.00278 362.747 4.425C363.168 4.79444 363.378 5.26944 363.378 5.85V14.7167C363.378 15.2972 363.168 15.7722 362.747 16.1417C362.379 16.5111 361.905 16.6958 361.327 16.6958H347.437V57.2292C347.437 57.8097 347.227 58.2847 346.806 58.6542C346.438 59.0236 345.964 59.2083 345.385 59.2083H334.416Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M361.601 59.2083C361.18 59.2083 360.786 59.05 360.417 58.7333C360.102 58.3639 359.944 57.9681 359.944 57.5458C359.944 57.2292 359.97 56.9917 360.023 56.8333L378.41 6.0875C378.936 4.55694 379.989 3.79166 381.567 3.79166H393.878C395.457 3.79166 396.509 4.55694 397.035 6.0875L415.423 56.8333C415.475 56.9917 415.502 57.2292 415.502 57.5458C415.502 57.9681 415.317 58.3639 414.949 58.7333C414.633 59.05 414.265 59.2083 413.844 59.2083H403.585C402.322 59.2083 401.428 58.6278 400.902 57.4667L398.061 49.7875H377.384L374.543 57.4667C374.017 58.6278 373.123 59.2083 371.86 59.2083H361.601ZM380.699 37.9125H394.746L387.723 17.725L380.699 37.9125Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M458.929 60C451.459 60 445.566 58.1792 441.252 54.5375C436.938 50.8958 434.649 45.5389 434.386 38.4667C434.334 36.9889 434.307 34.7194 434.307 31.6583C434.307 28.5972 434.334 26.3014 434.386 24.7708C434.597 17.8042 436.885 12.4472 441.252 8.7C445.671 4.9 451.564 3 458.929 3C466.243 3 472.082 4.9 476.449 8.7C480.869 12.4472 483.183 17.8042 483.394 24.7708C483.499 27.8319 483.552 30.1278 483.552 31.6583C483.552 33.2417 483.499 35.5111 483.394 38.4667C483.131 45.5389 480.842 50.8958 476.528 54.5375C472.267 58.1792 466.4 60 458.929 60ZM458.929 48.125C461.665 48.125 463.849 47.3069 465.48 45.6708C467.111 43.9819 467.979 41.4222 468.084 37.9917C468.189 34.9306 468.242 32.7403 468.242 31.4208C468.242 30.1014 468.189 27.9639 468.084 25.0083C467.979 21.5778 467.111 19.0444 465.48 17.4083C463.849 15.7194 461.665 14.875 458.929 14.875C456.141 14.875 453.931 15.7194 452.3 17.4083C450.669 19.0444 449.801 21.5778 449.696 25.0083C449.644 26.4861 449.617 28.6236 449.617 31.4208C449.617 34.2708 449.644 36.4611 449.696 37.9917C449.801 41.4222 450.669 43.9819 452.3 45.6708C453.931 47.3069 456.141 48.125 458.929 48.125Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M513.37 60C508.267 60 503.926 59.2611 500.349 57.7833C496.771 56.3056 494.062 54.3528 492.22 51.925C490.379 49.4972 489.406 46.8583 489.3 44.0083C489.3 43.5333 489.458 43.1375 489.774 42.8208C490.142 42.5042 490.563 42.3458 491.037 42.3458H501.533C502.164 42.3458 502.664 42.4514 503.032 42.6625C503.453 42.8736 503.9 43.2431 504.374 43.7708C505.058 45.0375 506.11 46.0931 507.53 46.9375C509.003 47.7292 510.95 48.125 513.37 48.125C516.211 48.125 518.395 47.7028 519.92 46.8583C521.446 45.9611 522.209 44.7472 522.209 43.2167C522.209 42.1083 521.814 41.2111 521.025 40.525C520.236 39.7861 518.973 39.1528 517.237 38.625C515.501 38.0444 513.002 37.4111 509.74 36.725C503.374 35.4583 498.613 33.5319 495.456 30.9458C492.299 28.3597 490.721 24.6653 490.721 19.8625C490.721 16.5903 491.615 13.6875 493.404 11.1542C495.245 8.56806 497.823 6.5625 501.138 5.1375C504.505 3.7125 508.398 3 512.818 3C517.448 3 521.446 3.81806 524.813 5.45417C528.233 7.0375 530.837 9.04306 532.626 11.4708C534.467 13.8458 535.441 16.1944 535.546 18.5167C535.546 18.9917 535.388 19.4139 535.072 19.7833C534.757 20.1 534.362 20.2583 533.889 20.2583H522.84C521.683 20.2583 520.788 19.7833 520.157 18.8333C519.841 17.725 519.026 16.8014 517.711 16.0625C516.395 15.2708 514.764 14.875 512.818 14.875C510.608 14.875 508.898 15.2708 507.688 16.0625C506.478 16.8542 505.873 18.0153 505.873 19.5458C505.873 21.0764 506.662 22.2903 508.241 23.1875C509.819 24.0319 512.765 24.9028 517.079 25.8C522.025 26.6972 525.944 27.8319 528.838 29.2042C531.784 30.5236 533.941 32.2653 535.309 34.4292C536.73 36.5403 537.44 39.2319 537.44 42.5042C537.44 46.0931 536.414 49.2069 534.362 51.8458C532.31 54.4847 529.469 56.5167 525.839 57.9417C522.209 59.3139 518.053 60 513.37 60Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
      <path d="M565.93 60C560.827 60 556.486 59.2611 552.909 57.7833C549.331 56.3056 546.622 54.3528 544.78 51.925C542.939 49.4972 541.966 46.8583 541.86 44.0083C541.86 43.5333 542.018 43.1375 542.334 42.8208C542.702 42.5042 543.123 42.3458 543.597 42.3458H554.093C554.724 42.3458 555.224 42.4514 555.592 42.6625C556.013 42.8736 556.46 43.2431 556.934 43.7708C557.618 45.0375 558.67 46.0931 560.09 46.9375C561.564 47.7292 563.51 48.125 565.93 48.125C568.771 48.125 570.955 47.7028 572.48 46.8583C574.006 45.9611 574.769 44.7472 574.769 43.2167C574.769 42.1083 574.374 41.2111 573.585 40.525C572.796 39.7861 571.533 39.1528 569.797 38.625C568.061 38.0444 565.562 37.4111 562.3 36.725C555.934 35.4583 551.173 33.5319 548.016 30.9458C544.859 28.3597 543.281 24.6653 543.281 19.8625C543.281 16.5903 544.175 13.6875 545.964 11.1542C547.806 8.56806 550.384 6.5625 553.698 5.1375C557.065 3.7125 560.958 3 565.378 3C570.008 3 574.006 3.81806 577.373 5.45417C580.793 7.0375 583.397 9.04306 585.186 11.4708C587.027 13.8458 588.001 16.1944 588.106 18.5167C588.106 18.9917 587.948 19.4139 587.633 19.7833C587.317 20.1 586.922 20.2583 586.449 20.2583H575.4C574.243 20.2583 573.348 19.7833 572.717 18.8333C572.401 17.725 571.586 16.8014 570.271 16.0625C568.955 15.2708 567.324 14.875 565.378 14.875C563.168 14.875 561.458 15.2708 560.248 16.0625C559.038 16.8542 558.433 18.0153 558.433 19.5458C558.433 21.0764 559.222 22.2903 560.801 23.1875C562.379 24.0319 565.325 24.9028 569.639 25.8C574.585 26.6972 578.504 27.8319 581.398 29.2042C584.344 30.5236 586.501 32.2653 587.869 34.4292C589.29 36.5403 590 39.2319 590 42.5042C590 46.0931 588.974 49.2069 586.922 51.8458C584.87 54.4847 582.029 56.5167 578.399 57.9417C574.769 59.3139 570.613 60 565.93 60Z" stroke="#FAF2FC" strokeWidth="6" mask="url(#contact-outline-mask)" />
    </svg>
  );
}
