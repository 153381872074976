import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';

const useStyles = makeStyles(theme => ({
  bottomPart: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 32,
    paddingLeft: 16,
    paddingRight: 32,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 48,
      paddingRight: 92,
    },
  },}));

export default function CardContentIconAndText({ htmlText, smallIcon, smallIconWidth }) {
  const classes = useStyles();
  return (
    <div className={classes.bottomPart}>
      <div style={{ width: smallIconWidth, marginBottom: 16 }}>
        {smallIcon}
      </div>
        <HtmlTextRenderer>
          {htmlText}
        </HtmlTextRenderer>
    </div>
  );
}