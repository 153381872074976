import React from 'react';
import { mapPackage } from '../packages/mapPackages';
import OnePackage from './OnePackage';

export default function OnePackageSlice({ sliceData }) {
  
  const primary = sliceData.primary;
  const backgroundColor = primary.background_color;
  const onePackage = mapPackage(primary.package.document.data)

  return (
    <OnePackage
      backgroundColor={backgroundColor}
      onePackage={onePackage}
      title={primary.title}
      packageSide={primary.package_side}
      largeText={primary.large_text}
      infoText={primary.info_text}
      buttonLabel={sliceData.primary.button_label}
      buttonPath={sliceData.primary.button_link.url}
    />
  );
}