import React from 'react';

export default function SakraSvg() {
  return (
    <svg width="270" height="76" viewBox="0 0 270 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="sakra-mask" maskUnits="userSpaceOnUse" x="0" y="0" width="270" height="76" fill="black">
        <rect fill="white" width="270" height="76" />
        <path d="M26.8182 73C21.7682 73 17.4731 72.2749 13.9329 70.8246C10.3927 69.3744 7.71157 67.458 5.88942 65.0755C4.06726 62.6929 3.10412 60.1032 3 57.3063C3 56.8402 3.15618 56.4517 3.46855 56.141C3.83298 55.8302 4.24948 55.6748 4.71803 55.6748H15.1043C15.729 55.6748 16.2236 55.7784 16.5881 55.9856C17.0046 56.1927 17.4471 56.5553 17.9156 57.0732C18.5924 58.3163 19.6337 59.3522 21.0393 60.1809C22.497 60.9578 24.4233 61.3463 26.8182 61.3463C29.6295 61.3463 31.79 60.9319 33.2998 60.1032C34.8096 59.2227 35.5645 58.0314 35.5645 56.5294C35.5645 55.4417 35.174 54.5612 34.3931 53.8879C33.6122 53.1628 32.3627 52.5413 30.6447 52.0233C28.9266 51.4536 26.4537 50.832 23.2259 50.1587C16.9265 48.9156 12.2149 47.0252 9.0912 44.4872C5.96751 41.9493 4.40566 38.3237 4.40566 33.6104C4.40566 30.3992 5.29071 27.5505 7.0608 25.0644C8.88295 22.5265 11.434 20.5583 14.7138 19.1598C18.0458 17.7614 21.8983 17.0622 26.2715 17.0622C30.8529 17.0622 34.8096 17.865 38.1415 19.4706C41.5255 21.0244 44.1026 22.9926 45.8727 25.3751C47.6948 27.7059 48.658 30.0107 48.7621 32.2897C48.7621 32.7558 48.6059 33.1702 48.2935 33.5327C47.9812 33.8435 47.5907 33.9989 47.1222 33.9989H36.1892C35.0439 33.9989 34.1588 33.5327 33.5341 32.6004C33.2217 31.5128 32.4148 30.6064 31.1132 29.8812C29.8117 29.1043 28.1978 28.7159 26.2715 28.7159C24.0849 28.7159 22.3929 29.1043 21.1955 29.8812C19.9981 30.6582 19.3994 31.7976 19.3994 33.2997C19.3994 34.8017 20.1803 35.993 21.7422 36.8735C23.304 37.7022 26.2194 38.5568 30.4885 39.4373C35.3823 40.3178 39.2609 41.4314 42.1242 42.778C45.0397 44.0729 47.1742 45.7821 48.5278 47.9057C49.9335 49.9774 50.6363 52.6189 50.6363 55.8302C50.6363 59.3522 49.6211 62.4081 47.5907 64.9978C45.5603 67.5875 42.749 69.5816 39.1567 70.98C35.5645 72.3267 31.4516 73 26.8182 73Z" />
        <path d="M53.4364 72.2231C53.02 72.2231 52.6295 72.0677 52.2651 71.7569C51.9527 71.3944 51.7965 71.0059 51.7965 70.5916C51.7965 70.2808 51.8225 70.0477 51.8746 69.8923L70.0701 20.0921C70.5907 18.5901 71.632 17.8391 73.1938 17.8391H85.3762C86.938 17.8391 87.9793 18.5901 88.4999 20.0921L106.695 69.8923C106.747 70.0477 106.773 70.2808 106.773 70.5916C106.773 71.0059 106.591 71.3944 106.227 71.7569C105.914 72.0677 105.55 72.2231 105.134 72.2231H94.9816C93.7321 72.2231 92.847 71.6534 92.3264 70.5139L89.5151 62.9778H69.0549L66.2436 70.5139C65.723 71.6534 64.8379 72.2231 63.5884 72.2231H53.4364ZM72.3348 51.3241H86.2352L79.285 31.5128L72.3348 51.3241ZM66.0874 13.9545C65.5668 13.9545 65.1503 13.7991 64.8379 13.4883C64.5256 13.1258 64.3694 12.6855 64.3694 12.1676V4.7869C64.3694 4.26896 64.5256 3.85461 64.8379 3.54384C65.1503 3.18128 65.5668 3 66.0874 3H73.5843C74.1049 3 74.5214 3.18128 74.8337 3.54384C75.1982 3.85461 75.3804 4.26896 75.3804 4.7869V12.1676C75.3804 12.6855 75.1982 13.1258 74.8337 13.4883C74.5214 13.7991 74.1049 13.9545 73.5843 13.9545H66.0874ZM84.9076 13.9545C84.387 13.9545 83.9445 13.7991 83.5801 13.4883C83.2677 13.1258 83.1115 12.6855 83.1115 12.1676V4.7869C83.1115 4.26896 83.2677 3.85461 83.5801 3.54384C83.9445 3.18128 84.387 3 84.9076 3H92.3264C92.847 3 93.2635 3.18128 93.5759 3.54384C93.9403 3.85461 94.1225 4.26896 94.1225 4.7869V12.1676C94.1225 12.6855 93.9403 13.1258 93.5759 13.4883C93.2635 13.7991 92.847 13.9545 92.3264 13.9545H84.9076Z" />
        <path d="M113.826 72.2231C113.305 72.2231 112.837 72.0418 112.42 71.6792C112.056 71.2649 111.874 70.7987 111.874 70.2808V19.859C111.874 19.2893 112.056 18.8232 112.42 18.4606C112.785 18.0462 113.253 17.8391 113.826 17.8391H124.29C124.863 17.8391 125.332 18.0462 125.696 18.4606C126.06 18.8232 126.243 19.2893 126.243 19.859V36.7958L140.065 19.3929C140.638 18.357 141.653 17.8391 143.111 17.8391H155.059C155.475 17.8391 155.84 18.0203 156.152 18.3829C156.516 18.6937 156.699 19.0562 156.699 19.4706C156.699 19.8849 156.595 20.1957 156.386 20.4029L138.191 43.8657L157.87 69.6593C158.078 69.8664 158.182 70.1772 158.182 70.5916C158.182 71.0059 158 71.3944 157.636 71.7569C157.323 72.0677 156.933 72.2231 156.464 72.2231H144.204C143.371 72.2231 142.694 72.0677 142.174 71.7569C141.653 71.3944 141.288 71.0318 141.08 70.6693L126.243 51.7125V70.2808C126.243 70.7987 126.06 71.2649 125.696 71.6792C125.332 72.0418 124.863 72.2231 124.29 72.2231H113.826Z" />
        <path d="M165.176 72.2231C164.655 72.2231 164.187 72.0418 163.77 71.6792C163.406 71.2649 163.223 70.7987 163.223 70.2808V19.859C163.223 19.2893 163.406 18.8232 163.77 18.4606C164.135 18.0462 164.603 17.8391 165.176 17.8391H186.651C193.523 17.8391 198.886 19.3929 202.738 22.5006C206.643 25.6082 208.595 29.9848 208.595 35.6304C208.595 39.256 207.736 42.3378 206.018 44.8757C204.352 47.4136 202.035 49.3818 199.068 50.7802L209.61 69.737C209.766 70.0477 209.845 70.3326 209.845 70.5916C209.845 71.0059 209.688 71.3944 209.376 71.7569C209.064 72.0677 208.673 72.2231 208.205 72.2231H197.194C195.684 72.2231 194.617 71.5239 193.992 70.1254L185.402 53.2664H177.905V70.2808C177.905 70.8505 177.697 71.3167 177.28 71.6792C176.916 72.0418 176.447 72.2231 175.874 72.2231H165.176ZM186.573 41.8457C188.812 41.8457 190.504 41.3019 191.649 40.2142C192.846 39.0747 193.445 37.5209 193.445 35.5527C193.445 33.5845 192.846 32.0048 191.649 30.8135C190.504 29.6223 188.812 29.0266 186.573 29.0266H177.905V41.8457H186.573Z" />
        <path d="M213.663 72.2231C213.246 72.2231 212.856 72.0677 212.492 71.7569C212.179 71.3944 212.023 71.0059 212.023 70.5916C212.023 70.2808 212.049 70.0477 212.101 69.8923L230.297 20.0921C230.817 18.5901 231.858 17.8391 233.42 17.8391H245.603C247.165 17.8391 248.206 18.5901 248.726 20.0921L266.922 69.8923C266.974 70.0477 267 70.2808 267 70.5916C267 71.0059 266.818 71.3944 266.453 71.7569C266.141 72.0677 265.777 72.2231 265.36 72.2231H255.208C253.959 72.2231 253.074 71.6534 252.553 70.5139L249.742 62.9778H229.281L226.47 70.5139C225.949 71.6534 225.064 72.2231 223.815 72.2231H213.663ZM232.561 51.3241H246.462L239.512 31.5128L232.561 51.3241Z" />
      </mask>
      <path d="M26.8182 73C21.7682 73 17.4731 72.2749 13.9329 70.8246C10.3927 69.3744 7.71157 67.458 5.88942 65.0755C4.06726 62.6929 3.10412 60.1032 3 57.3063C3 56.8402 3.15618 56.4517 3.46855 56.141C3.83298 55.8302 4.24948 55.6748 4.71803 55.6748H15.1043C15.729 55.6748 16.2236 55.7784 16.5881 55.9856C17.0046 56.1927 17.4471 56.5553 17.9156 57.0732C18.5924 58.3163 19.6337 59.3522 21.0393 60.1809C22.497 60.9578 24.4233 61.3463 26.8182 61.3463C29.6295 61.3463 31.79 60.9319 33.2998 60.1032C34.8096 59.2227 35.5645 58.0314 35.5645 56.5294C35.5645 55.4417 35.174 54.5612 34.3931 53.8879C33.6122 53.1628 32.3627 52.5413 30.6447 52.0233C28.9266 51.4536 26.4537 50.832 23.2259 50.1587C16.9265 48.9156 12.2149 47.0252 9.0912 44.4872C5.96751 41.9493 4.40566 38.3237 4.40566 33.6104C4.40566 30.3992 5.29071 27.5505 7.0608 25.0644C8.88295 22.5265 11.434 20.5583 14.7138 19.1598C18.0458 17.7614 21.8983 17.0622 26.2715 17.0622C30.8529 17.0622 34.8096 17.865 38.1415 19.4706C41.5255 21.0244 44.1026 22.9926 45.8727 25.3751C47.6948 27.7059 48.658 30.0107 48.7621 32.2897C48.7621 32.7558 48.6059 33.1702 48.2935 33.5327C47.9812 33.8435 47.5907 33.9989 47.1222 33.9989H36.1892C35.0439 33.9989 34.1588 33.5327 33.5341 32.6004C33.2217 31.5128 32.4148 30.6064 31.1132 29.8812C29.8117 29.1043 28.1978 28.7159 26.2715 28.7159C24.0849 28.7159 22.3929 29.1043 21.1955 29.8812C19.9981 30.6582 19.3994 31.7976 19.3994 33.2997C19.3994 34.8017 20.1803 35.993 21.7422 36.8735C23.304 37.7022 26.2194 38.5568 30.4885 39.4373C35.3823 40.3178 39.2609 41.4314 42.1242 42.778C45.0397 44.0729 47.1742 45.7821 48.5278 47.9057C49.9335 49.9774 50.6363 52.6189 50.6363 55.8302C50.6363 59.3522 49.6211 62.4081 47.5907 64.9978C45.5603 67.5875 42.749 69.5816 39.1567 70.98C35.5645 72.3267 31.4516 73 26.8182 73Z" stroke="#090213" strokeWidth="6" mask="url(#sakra-mask)" />
      <path d="M53.4364 72.2231C53.02 72.2231 52.6295 72.0677 52.2651 71.7569C51.9527 71.3944 51.7965 71.0059 51.7965 70.5916C51.7965 70.2808 51.8225 70.0477 51.8746 69.8923L70.0701 20.0921C70.5907 18.5901 71.632 17.8391 73.1938 17.8391H85.3762C86.938 17.8391 87.9793 18.5901 88.4999 20.0921L106.695 69.8923C106.747 70.0477 106.773 70.2808 106.773 70.5916C106.773 71.0059 106.591 71.3944 106.227 71.7569C105.914 72.0677 105.55 72.2231 105.134 72.2231H94.9816C93.7321 72.2231 92.847 71.6534 92.3264 70.5139L89.5151 62.9778H69.0549L66.2436 70.5139C65.723 71.6534 64.8379 72.2231 63.5884 72.2231H53.4364ZM72.3348 51.3241H86.2352L79.285 31.5128L72.3348 51.3241ZM66.0874 13.9545C65.5668 13.9545 65.1503 13.7991 64.8379 13.4883C64.5256 13.1258 64.3694 12.6855 64.3694 12.1676V4.7869C64.3694 4.26896 64.5256 3.85461 64.8379 3.54384C65.1503 3.18128 65.5668 3 66.0874 3H73.5843C74.1049 3 74.5214 3.18128 74.8337 3.54384C75.1982 3.85461 75.3804 4.26896 75.3804 4.7869V12.1676C75.3804 12.6855 75.1982 13.1258 74.8337 13.4883C74.5214 13.7991 74.1049 13.9545 73.5843 13.9545H66.0874ZM84.9076 13.9545C84.387 13.9545 83.9445 13.7991 83.5801 13.4883C83.2677 13.1258 83.1115 12.6855 83.1115 12.1676V4.7869C83.1115 4.26896 83.2677 3.85461 83.5801 3.54384C83.9445 3.18128 84.387 3 84.9076 3H92.3264C92.847 3 93.2635 3.18128 93.5759 3.54384C93.9403 3.85461 94.1225 4.26896 94.1225 4.7869V12.1676C94.1225 12.6855 93.9403 13.1258 93.5759 13.4883C93.2635 13.7991 92.847 13.9545 92.3264 13.9545H84.9076Z" stroke="#090213" strokeWidth="6" mask="url(#sakra-mask)" />
      <path d="M113.826 72.2231C113.305 72.2231 112.837 72.0418 112.42 71.6792C112.056 71.2649 111.874 70.7987 111.874 70.2808V19.859C111.874 19.2893 112.056 18.8232 112.42 18.4606C112.785 18.0462 113.253 17.8391 113.826 17.8391H124.29C124.863 17.8391 125.332 18.0462 125.696 18.4606C126.06 18.8232 126.243 19.2893 126.243 19.859V36.7958L140.065 19.3929C140.638 18.357 141.653 17.8391 143.111 17.8391H155.059C155.475 17.8391 155.84 18.0203 156.152 18.3829C156.516 18.6937 156.699 19.0562 156.699 19.4706C156.699 19.8849 156.595 20.1957 156.386 20.4029L138.191 43.8657L157.87 69.6593C158.078 69.8664 158.182 70.1772 158.182 70.5916C158.182 71.0059 158 71.3944 157.636 71.7569C157.323 72.0677 156.933 72.2231 156.464 72.2231H144.204C143.371 72.2231 142.694 72.0677 142.174 71.7569C141.653 71.3944 141.288 71.0318 141.08 70.6693L126.243 51.7125V70.2808C126.243 70.7987 126.06 71.2649 125.696 71.6792C125.332 72.0418 124.863 72.2231 124.29 72.2231H113.826Z" stroke="#090213" strokeWidth="6" mask="url(#sakra-mask)" />
      <path d="M165.176 72.2231C164.655 72.2231 164.187 72.0418 163.77 71.6792C163.406 71.2649 163.223 70.7987 163.223 70.2808V19.859C163.223 19.2893 163.406 18.8232 163.77 18.4606C164.135 18.0462 164.603 17.8391 165.176 17.8391H186.651C193.523 17.8391 198.886 19.3929 202.738 22.5006C206.643 25.6082 208.595 29.9848 208.595 35.6304C208.595 39.256 207.736 42.3378 206.018 44.8757C204.352 47.4136 202.035 49.3818 199.068 50.7802L209.61 69.737C209.766 70.0477 209.845 70.3326 209.845 70.5916C209.845 71.0059 209.688 71.3944 209.376 71.7569C209.064 72.0677 208.673 72.2231 208.205 72.2231H197.194C195.684 72.2231 194.617 71.5239 193.992 70.1254L185.402 53.2664H177.905V70.2808C177.905 70.8505 177.697 71.3167 177.28 71.6792C176.916 72.0418 176.447 72.2231 175.874 72.2231H165.176ZM186.573 41.8457C188.812 41.8457 190.504 41.3019 191.649 40.2142C192.846 39.0747 193.445 37.5209 193.445 35.5527C193.445 33.5845 192.846 32.0048 191.649 30.8135C190.504 29.6223 188.812 29.0266 186.573 29.0266H177.905V41.8457H186.573Z" stroke="#090213" strokeWidth="6" mask="url(#sakra-mask)" />
      <path d="M213.663 72.2231C213.246 72.2231 212.856 72.0677 212.492 71.7569C212.179 71.3944 212.023 71.0059 212.023 70.5916C212.023 70.2808 212.049 70.0477 212.101 69.8923L230.297 20.0921C230.817 18.5901 231.858 17.8391 233.42 17.8391H245.603C247.165 17.8391 248.206 18.5901 248.726 20.0921L266.922 69.8923C266.974 70.0477 267 70.2808 267 70.5916C267 71.0059 266.818 71.3944 266.453 71.7569C266.141 72.0677 265.777 72.2231 265.36 72.2231H255.208C253.959 72.2231 253.074 71.6534 252.553 70.5139L249.742 62.9778H229.281L226.47 70.5139C225.949 71.6534 225.064 72.2231 223.815 72.2231H213.663ZM232.561 51.3241H246.462L239.512 31.5128L232.561 51.3241Z" stroke="#090213" strokeWidth="6" mask="url(#sakra-mask)" />
    </svg>
  );
}
