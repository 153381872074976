import React from 'react';

export default function ContactWhiteFilledSvg() {
  return (
    <svg width="587" height="57" viewBox="0 0 587 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.97293 56.2083C1.44682 56.2083 0.973314 56.0236 0.552421 55.6542C0.184141 55.2319 0 54.7569 0 54.2292V2.85C0 2.26944 0.184141 1.79444 0.552421 1.425C0.920702 1.00278 1.39421 0.791665 1.97293 0.791665H12.5479C13.1266 0.791665 13.6001 1.00278 13.9684 1.425C14.3366 1.79444 14.5208 2.26944 14.5208 2.85V20.1083L28.4892 2.375C29.0679 1.31945 30.0938 0.791665 31.5669 0.791665H43.6413C44.0622 0.791665 44.4305 0.976389 44.7461 1.34584C45.1144 1.6625 45.2985 2.03194 45.2985 2.45417C45.2985 2.87639 45.1933 3.19306 44.9829 3.40416L26.5951 27.3125L46.4823 53.5958C46.6928 53.8069 46.798 54.1236 46.798 54.5458C46.798 54.9681 46.6138 55.3639 46.2456 55.7333C45.9299 56.05 45.5353 56.2083 45.0618 56.2083H32.6718C31.83 56.2083 31.146 56.05 30.6199 55.7333C30.0938 55.3639 29.7255 54.9944 29.5151 54.625L14.5208 35.3083V54.2292C14.5208 54.7569 14.3366 55.2319 13.9684 55.6542C13.6001 56.0236 13.1266 56.2083 12.5479 56.2083H1.97293Z" fill="#FAF2FC" />
      <path d="M72.8259 57C65.3551 57 59.4626 55.1792 55.1484 51.5375C50.8343 47.8958 48.5457 42.5389 48.2826 35.4667C48.23 33.9889 48.2037 31.7194 48.2037 28.6583C48.2037 25.5972 48.23 23.3014 48.2826 21.7708C48.4931 14.8042 50.7817 9.44722 55.1484 5.7C59.5678 1.9 65.4603 0 72.8259 0C80.1389 0 85.9788 1.9 90.3456 5.7C94.7649 9.44722 97.0798 14.8042 97.2903 21.7708C97.3955 24.8319 97.4481 27.1278 97.4481 28.6583C97.4481 30.2417 97.3955 32.5111 97.2903 35.4667C97.0272 42.5389 94.7386 47.8958 90.4245 51.5375C86.1629 55.1792 80.2967 57 72.8259 57ZM72.8259 45.125C75.5617 45.125 77.7451 44.3069 79.376 42.6708C81.007 40.9819 81.8751 38.4222 81.9803 34.9917C82.0855 31.9306 82.1382 29.7403 82.1382 28.4208C82.1382 27.1014 82.0855 24.9639 81.9803 22.0083C81.8751 18.5778 81.007 16.0444 79.376 14.4083C77.7451 12.7194 75.5617 11.875 72.8259 11.875C70.0375 11.875 67.8278 12.7194 66.1969 14.4083C64.5659 16.0444 63.6978 18.5778 63.5926 22.0083C63.54 23.4861 63.5137 25.6236 63.5137 28.4208C63.5137 31.2708 63.54 33.4611 63.5926 34.9917C63.6978 38.4222 64.5659 40.9819 66.1969 42.6708C67.8278 44.3069 70.0375 45.125 72.8259 45.125Z" fill="#FAF2FC" />
      <path d="M108.326 56.2083C107.8 56.2083 107.327 56.0236 106.906 55.6542C106.538 55.2319 106.353 54.7569 106.353 54.2292V2.85C106.353 2.26944 106.538 1.79444 106.906 1.425C107.274 1.00278 107.748 0.791665 108.326 0.791665H117.244C118.454 0.791665 119.348 1.29305 119.927 2.29583L138.552 31.825V2.85C138.552 2.26944 138.736 1.79444 139.104 1.425C139.472 1.00278 139.946 0.791665 140.525 0.791665H150.547C151.126 0.791665 151.599 1.00278 151.968 1.425C152.336 1.79444 152.52 2.26944 152.52 2.85V54.15C152.52 54.7306 152.336 55.2319 151.968 55.6542C151.599 56.0236 151.126 56.2083 150.547 56.2083H141.629C140.419 56.2083 139.525 55.7069 138.946 54.7042L120.401 26.3625V54.2292C120.401 54.8097 120.19 55.2847 119.769 55.6542C119.401 56.0236 118.928 56.2083 118.349 56.2083H108.326Z" fill="#FAF2FC" />
      <path d="M177.435 56.2083C176.856 56.2083 176.356 56.0236 175.935 55.6542C175.567 55.2847 175.383 54.8097 175.383 54.2292V13.6958H161.493C160.915 13.6958 160.415 13.5111 159.994 13.1417C159.626 12.7722 159.441 12.2972 159.441 11.7167V2.85C159.441 2.26944 159.626 1.79444 159.994 1.425C160.415 1.00278 160.915 0.791665 161.493 0.791665H204.345C204.924 0.791665 205.398 1.00278 205.766 1.425C206.187 1.79444 206.397 2.26944 206.397 2.85V11.7167C206.397 12.2972 206.187 12.7722 205.766 13.1417C205.398 13.5111 204.924 13.6958 204.345 13.6958H190.456V54.2292C190.456 54.8097 190.245 55.2847 189.825 55.6542C189.456 56.0236 188.983 56.2083 188.404 56.2083H177.435Z" fill="#FAF2FC" />
      <path d="M204.62 56.2083C204.199 56.2083 203.804 56.05 203.436 55.7333C203.12 55.3639 202.962 54.9681 202.962 54.5458C202.962 54.2292 202.989 53.9917 203.041 53.8333L221.429 3.0875C221.955 1.55694 223.007 0.791665 224.586 0.791665H236.897C238.475 0.791665 239.527 1.55694 240.054 3.0875L258.441 53.8333C258.494 53.9917 258.52 54.2292 258.52 54.5458C258.52 54.9681 258.336 55.3639 257.968 55.7333C257.652 56.05 257.284 56.2083 256.863 56.2083H246.604C245.341 56.2083 244.447 55.6278 243.921 54.4667L241.08 46.7875H220.403L217.562 54.4667C217.036 55.6278 216.142 56.2083 214.879 56.2083H204.62ZM223.718 34.9125H237.765L230.741 14.725L223.718 34.9125Z" fill="#FAF2FC" />
      <path d="M266.469 56.2083C265.943 56.2083 265.47 56.0236 265.049 55.6542C264.681 55.2319 264.496 54.7569 264.496 54.2292V2.85C264.496 2.26944 264.681 1.79444 265.049 1.425C265.417 1.00278 265.891 0.791665 266.469 0.791665H277.044C277.623 0.791665 278.096 1.00278 278.465 1.425C278.833 1.79444 279.017 2.26944 279.017 2.85V20.1083L292.986 2.375C293.564 1.31945 294.59 0.791665 296.063 0.791665H308.138C308.559 0.791665 308.927 0.976389 309.243 1.34584C309.611 1.6625 309.795 2.03194 309.795 2.45417C309.795 2.87639 309.69 3.19306 309.479 3.40416L291.092 27.3125L310.979 53.5958C311.189 53.8069 311.294 54.1236 311.294 54.5458C311.294 54.9681 311.11 55.3639 310.742 55.7333C310.426 56.05 310.032 56.2083 309.558 56.2083H297.168C296.326 56.2083 295.642 56.05 295.116 55.7333C294.59 55.3639 294.222 54.9944 294.011 54.625L279.017 35.3083V54.2292C279.017 54.7569 278.833 55.2319 278.465 55.6542C278.096 56.0236 277.623 56.2083 277.044 56.2083H266.469Z" fill="#FAF2FC" />
      <path d="M331.416 56.2083C330.837 56.2083 330.337 56.0236 329.916 55.6542C329.548 55.2847 329.364 54.8097 329.364 54.2292V13.6958H315.475C314.896 13.6958 314.396 13.5111 313.975 13.1417C313.607 12.7722 313.423 12.2972 313.423 11.7167V2.85C313.423 2.26944 313.607 1.79444 313.975 1.425C314.396 1.00278 314.896 0.791665 315.475 0.791665H358.327C358.905 0.791665 359.379 1.00278 359.747 1.425C360.168 1.79444 360.378 2.26944 360.378 2.85V11.7167C360.378 12.2972 360.168 12.7722 359.747 13.1417C359.379 13.5111 358.905 13.6958 358.327 13.6958H344.437V54.2292C344.437 54.8097 344.227 55.2847 343.806 55.6542C343.438 56.0236 342.964 56.2083 342.385 56.2083H331.416Z" fill="#FAF2FC" />
      <path d="M358.601 56.2083C358.18 56.2083 357.785 56.05 357.417 55.7333C357.102 55.3639 356.944 54.9681 356.944 54.5458C356.944 54.2292 356.97 53.9917 357.023 53.8333L375.41 3.0875C375.936 1.55694 376.989 0.791665 378.567 0.791665H390.878C392.457 0.791665 393.509 1.55694 394.035 3.0875L412.423 53.8333C412.475 53.9917 412.502 54.2292 412.502 54.5458C412.502 54.9681 412.317 55.3639 411.949 55.7333C411.633 56.05 411.265 56.2083 410.844 56.2083H400.585C399.322 56.2083 398.428 55.6278 397.902 54.4667L395.061 46.7875H374.384L371.543 54.4667C371.017 55.6278 370.123 56.2083 368.86 56.2083H358.601ZM377.699 34.9125H391.746L384.723 14.725L377.699 34.9125Z" fill="#FAF2FC" />
      <path d="M455.93 57C448.459 57 442.566 55.1792 438.252 51.5375C433.938 47.8958 431.649 42.5389 431.386 35.4667C431.334 33.9889 431.307 31.7194 431.307 28.6583C431.307 25.5972 431.334 23.3014 431.386 21.7708C431.597 14.8042 433.885 9.44722 438.252 5.7C442.671 1.9 448.564 0 455.93 0C463.242 0 469.082 1.9 473.449 5.7C477.869 9.44722 480.183 14.8042 480.394 21.7708C480.499 24.8319 480.552 27.1278 480.552 28.6583C480.552 30.2417 480.499 32.5111 480.394 35.4667C480.131 42.5389 477.842 47.8958 473.528 51.5375C469.267 55.1792 463.4 57 455.93 57ZM455.93 45.125C458.665 45.125 460.849 44.3069 462.48 42.6708C464.111 40.9819 464.979 38.4222 465.084 34.9917C465.189 31.9306 465.242 29.7403 465.242 28.4208C465.242 27.1014 465.189 24.9639 465.084 22.0083C464.979 18.5778 464.111 16.0444 462.48 14.4083C460.849 12.7194 458.665 11.875 455.93 11.875C453.141 11.875 450.931 12.7194 449.3 14.4083C447.669 16.0444 446.801 18.5778 446.696 22.0083C446.644 23.4861 446.617 25.6236 446.617 28.4208C446.617 31.2708 446.644 33.4611 446.696 34.9917C446.801 38.4222 447.669 40.9819 449.3 42.6708C450.931 44.3069 453.141 45.125 455.93 45.125Z" fill="#FAF2FC" />
      <path d="M510.37 57C505.267 57 500.926 56.2611 497.349 54.7833C493.771 53.3056 491.062 51.3528 489.22 48.925C487.379 46.4972 486.406 43.8583 486.3 41.0083C486.3 40.5333 486.458 40.1375 486.774 39.8208C487.142 39.5042 487.563 39.3458 488.036 39.3458H498.533C499.164 39.3458 499.664 39.4514 500.032 39.6625C500.453 39.8736 500.9 40.2431 501.374 40.7708C502.057 42.0375 503.11 43.0931 504.53 43.9375C506.003 44.7292 507.95 45.125 510.37 45.125C513.211 45.125 515.395 44.7028 516.92 43.8583C518.446 42.9611 519.209 41.7472 519.209 40.2167C519.209 39.1083 518.814 38.2111 518.025 37.525C517.236 36.7861 515.973 36.1528 514.237 35.625C512.501 35.0444 510.002 34.4111 506.74 33.725C500.374 32.4583 495.613 30.5319 492.456 27.9458C489.299 25.3597 487.721 21.6653 487.721 16.8625C487.721 13.5903 488.615 10.6875 490.404 8.15417C492.245 5.56806 494.823 3.5625 498.138 2.1375C501.505 0.712499 505.398 0 509.818 0C514.448 0 518.446 0.818055 521.813 2.45417C525.233 4.0375 527.837 6.04306 529.626 8.47083C531.467 10.8458 532.441 13.1944 532.546 15.5167C532.546 15.9917 532.388 16.4139 532.072 16.7833C531.757 17.1 531.362 17.2583 530.889 17.2583H519.84C518.683 17.2583 517.788 16.7833 517.157 15.8333C516.841 14.725 516.026 13.8014 514.711 13.0625C513.395 12.2708 511.764 11.875 509.818 11.875C507.608 11.875 505.898 12.2708 504.688 13.0625C503.478 13.8542 502.873 15.0153 502.873 16.5458C502.873 18.0764 503.662 19.2903 505.241 20.1875C506.819 21.0319 509.765 21.9028 514.079 22.8C519.025 23.6972 522.944 24.8319 525.838 26.2042C528.784 27.5236 530.941 29.2653 532.309 31.4292C533.73 33.5403 534.44 36.2319 534.44 39.5042C534.44 43.0931 533.414 46.2069 531.362 48.8458C529.31 51.4847 526.469 53.5167 522.839 54.9417C519.209 56.3139 515.053 57 510.37 57Z" fill="#FAF2FC" />
      <path d="M562.93 57C557.827 57 553.487 56.2611 549.909 54.7833C546.331 53.3056 543.622 51.3528 541.78 48.925C539.939 46.4972 538.966 43.8583 538.86 41.0083C538.86 40.5333 539.018 40.1375 539.334 39.8208C539.702 39.5042 540.123 39.3458 540.597 39.3458H551.093C551.724 39.3458 552.224 39.4514 552.592 39.6625C553.013 39.8736 553.46 40.2431 553.934 40.7708C554.618 42.0375 555.67 43.0931 557.09 43.9375C558.564 44.7292 560.51 45.125 562.93 45.125C565.771 45.125 567.955 44.7028 569.48 43.8583C571.006 42.9611 571.769 41.7472 571.769 40.2167C571.769 39.1083 571.374 38.2111 570.585 37.525C569.796 36.7861 568.533 36.1528 566.797 35.625C565.061 35.0444 562.562 34.4111 559.3 33.725C552.934 32.4583 548.173 30.5319 545.016 27.9458C541.859 25.3597 540.281 21.6653 540.281 16.8625C540.281 13.5903 541.175 10.6875 542.964 8.15417C544.806 5.56806 547.384 3.5625 550.698 2.1375C554.065 0.712499 557.958 0 562.378 0C567.008 0 571.006 0.818055 574.373 2.45417C577.793 4.0375 580.397 6.04306 582.186 8.47083C584.027 10.8458 585.001 13.1944 585.106 15.5167C585.106 15.9917 584.948 16.4139 584.633 16.7833C584.317 17.1 583.922 17.2583 583.449 17.2583H572.4C571.243 17.2583 570.349 16.7833 569.717 15.8333C569.401 14.725 568.586 13.8014 567.271 13.0625C565.955 12.2708 564.324 11.875 562.378 11.875C560.168 11.875 558.458 12.2708 557.248 13.0625C556.038 13.8542 555.433 15.0153 555.433 16.5458C555.433 18.0764 556.222 19.2903 557.801 20.1875C559.379 21.0319 562.325 21.9028 566.639 22.8C571.585 23.6972 575.504 24.8319 578.398 26.2042C581.344 27.5236 583.501 29.2653 584.869 31.4292C586.29 33.5403 587 36.2319 587 39.5042C587 43.0931 585.974 46.2069 583.922 48.8458C581.87 51.4847 579.029 53.5167 575.399 54.9417C571.769 56.3139 567.613 57 562.93 57Z" fill="#FAF2FC" />
    </svg>

  );
}
