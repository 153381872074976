import React from 'react';
import LargeTextWithListSlice from "./large-text-with-list/LargeTextWithListSlice";
import ContactBannerSlice from './contact-banner/ContactBannerSlice';
import ContactUsFormSlice from './contact-us-form/ContactUsFormSlice';
import SkillsCardSlice from './skills-cards/SkillsCardSlice';
import HeroTextIconSlice from './hero-text-icon/HeroTextIconSlice';
import InfoCardsSlice from './info-cards/InfoCardsSlice';
import HeroBannerAnimationSlice from './hero-banner-animation/HeroBannerAnimationSlice';
import LightHouseTestSlice from './lighthouse-test/LightHouseTestSlice';
import TextAreaSlice from './blog/text-area/TextAreaSlice';
import QuoteSlice from './blog/quote/QuoteSlice';
import CenteredImageSlice from './blog/centered-image/CenteredImageSlice';
import FullWidthImageSlice from './blog/full-width-image/FullWidthImageSlice';
import CodeSnippetSlice from './blog/code-snippet/CodeSnippetSlice';
import LargeTitleSlice from './large-title/LargeTitleSlice';
import TextWithPrimaryButtonSlice from './text-with-primary-button/TextWithPrimaryButtonSlice';
import EmptyBackgroundSlice from './empty-background/EmptyBackgroundSlice';
import PackagesSlice from './packages/PackagesSlice';
import OnePackageSlice from './one-package/OnePackageSlice';
import PackageDescriptionsSlice from './package-descriptions/PackageDescriptionsSlice';
import HugeButtonHeroSlice from './huge-button-hero/HugeButtonHeroSlice';

const SliceZone = ({ slices }) => {

  const sliceComponents = {
    largetextwithlist: LargeTextWithListSlice,
    contactbanner: ContactBannerSlice,
    herobanneranimation: HeroBannerAnimationSlice,
    contactusform: ContactUsFormSlice,
    skillscards: SkillsCardSlice,
    herotexticon: HeroTextIconSlice,
    cards: InfoCardsSlice,
    lighthousetest: LightHouseTestSlice,
    textarea: TextAreaSlice,
    quote: QuoteSlice,
    centeredimage: CenteredImageSlice,
    fullwidthimage: FullWidthImageSlice,
    codesnippet: CodeSnippetSlice,
    largetitle: LargeTitleSlice,
    textwithprimarybutton: TextWithPrimaryButtonSlice,
    emptybackground: EmptyBackgroundSlice,
    packages: PackagesSlice,
    onepackage: OnePackageSlice,
    packagedescriptions: PackageDescriptionsSlice,
    hugebuttonhero: HugeButtonHeroSlice
  }

  const sliceZoneContent = slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent sliceData={slice} key={`slice-${index}`} />
    }
    return null
  })

  return sliceZoneContent;
}

export default SliceZone