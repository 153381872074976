import React from 'react';
import ContactUsForm from '../../components/forms/contactUsForm';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    backgroundColor: theme.palette.primaryYellow.light1,
  },
}));

export default function ContactUsFullWidth() {

  const classes = useStyles();

  return (
    <section className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '80%',
          top: 0,
          bottom: 0,
        }}
        mobileSizes={{
          width: '88%',
          top: 32,
          bottom: 32,
        }}
      >
        <ContactUsForm howToHelpField={true} />
      </ResponsiveMarginContainer>
    </section>
  );
}