import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UnderlinedText from '../../components/text/underlinedText';
import { Typography } from '@material-ui/core';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';
import PrimaryButtonLink from '../../components/input/button/PrimaryButtonLink';

const useStyles = makeStyles(theme => ({
  outerTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 48,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 0,
    },
  },
  textItemWrapper: {
    maxWidth: 533,
    marginLeft: 24,
    marginRight: 24,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      paddingLeft: 48,
      paddingRight: 48,
    },
  },
  titleWrapper: {
    paddingTop: 24,
    paddingBottom: 12,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 48,
      paddingBottom: 32,
    },
  }
}));

export default function PackageTextContainer({ title, largeText, infoText, buttonLabel, buttonPath }) {

  const classes = useStyles();

  return (
    <div className={classes.outerTextContainer}>
      <div className={classes.textItemWrapper}>
        <UnderlinedText>
          {title}
        </UnderlinedText>
        <div className={classes.titleWrapper}>
          <Typography variant="h2">
            {largeText}
          </Typography>
        </div>
        <HtmlTextRenderer>
          {infoText.html}
        </HtmlTextRenderer>
        {buttonLabel && (
          <div style={{ paddingTop: 16 }}>
            <PrimaryButtonLink label={buttonLabel} to={buttonPath} />
          </div>
        )}
      </div>
    </div>
  );
}
