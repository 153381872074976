import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PrimaryTextFieldInput from '../../components/input/text/PrimaryTextFieldInput';
import HardShadowButton from '../../components/input/button/HardShadowButton';
import ExternalLink from '../../components/stuff/ExternalLink';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';

const useStyles = makeStyles(theme => ({
  container: {  
    backgroundColor: theme.palette.primaryPink.light1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 96,
    paddingBottom: 128,
  },
  textContainer: {
    width: 600
  },
  title: {
    textTransform: 'uppercase'
  },
  inputContainer: {
    display: 'flex',
    width: '70%',
    paddingTop: 64,
  },
  input: {
    width: '100%',
    paddingRight: 128
  },
}));

export default function LightHouseTest({ title, infoText }) {
  const classes = useStyles();

  const [website, setWebsite] = useState('')

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
      <Typography className={classes.title} variant="h2">{title}</Typography>
      <HtmlTextRenderer>
        {infoText}
      </HtmlTextRenderer>
      </div>
      <div className={classes.inputContainer}>
        <div className={classes.input}>
          <PrimaryTextFieldInput onChange={(event) => setWebsite(event.target.value)} placeholder="DIN HEMSIDA"></PrimaryTextFieldInput>
        </div>
        <div>
          <ExternalLink href={`https://developers.google.com/speed/pagespeed/insights/?url=${website}`} target="__blank">
            <HardShadowButton label="TESTA!"></HardShadowButton>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}
