import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PackageActionButton from './PackageActionButton';
import PackageItem from './PackageItem';

const useStyles = makeStyles(theme => ({
  container: { 
    marginBottom: 64,
    border: theme.custom.standardBorder,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 533,
    },
    marginLeft: 12,
    marginRight: 12,
  },
  topSection: {
    backgroundColor: settings => settings.backgroundColor
  },
  titleSection: {
    borderBottom: theme.custom.standardBorder,
    paddingLeft: 42,
    paddingTop: 12,
    paddingBottom: 12,
  },
  textSection: {
    borderBottom: theme.custom.standardBorder,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 32,
    paddingBottom: 48,
  },
  subTitle: {
    paddingTop: 12,
  },
  description: {
    maxWidth: 350,
    paddingTop: 16,
  },

}));

export default function Package({ packageData }) {
  const settings = {
    backgroundColor: packageData.packageColor,
  }
  const classes = useStyles(settings);
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div className={classes.titleSection}>
          <Typography variant="h5">
            {packageData.title}
          </Typography>
        </div>

        <div className={classes.textSection}>
          <Typography variant="h3">
            {packageData.title}
          </Typography>
          {packageData.subTitle && (
            <div className={classes.subTitle}>
              <Typography variant="h5">
                {packageData.subTitle}
              </Typography>
            </div>
          )}
          <div className={classes.description}>
            <Typography variant="body1">
              {packageData.description}
            </Typography>
          </div>
        </div>

      </div>
      {packageData.items.map(item => {
        return <PackageItem
                  key={`${packageData.title}-${item.title}`}
                  packageItem={item} 
                  backgroundColor={packageData.itemBackgroundColor} 
                  hoverColor={packageData.itemHoverColor}
                />
      })}
      <PackageActionButton 
              label={packageData.actionButtonLabel}
              link={packageData.actionButtonLink}
              anchorTag={packageData.actionButtonAnchorTag}
              backgroundColor={packageData.actionButtonColor}
      />
    </div>
  );
}
