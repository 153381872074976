import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { motion } from "framer-motion"

const useStyles = makeStyles(theme => ({
  container: { 
    width: '100%',
    maxWidth: settings => settings.maxWidth, 
    height: settings => settings.mobileHeight,
    [theme.breakpoints.up('sm')]:{
      height: settings => settings.desktopHeight,
    },
    boxShadow: '0 0 0 3px #090213',
    backgroundColor: theme.palette.primaryYellow.main,
    overflow: 'hidden',
  },
  innerCard: {
    width: '100%',
    height: '100%',
  },
  upperPart: {
    height: '85%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titlePart: {
    height: '15%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 48,
    boxShadow: '0 0 0 3px #090213',
  },
  bottomContent: {
    height: '85%'
  },
  title: {      
    fontFamily: 'Rubik, helvetica, sans-serif',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.334,
    letterSpacing: '0em',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
  },
  smallPng: {
    width: 115,
  }
}));

export default function SmallAnimatedCard({ maxWidth, desktopHeight, mobileHeight, title, largeIcon, bottomContent, largeIconWidth}) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const settings = {
    maxWidth: maxWidth ? maxWidth : 562,
    desktopHeight: desktopHeight ? desktopHeight : 400,
    mobileHeight: mobileHeight ? mobileHeight : 550
  };

  const classes = useStyles(settings);

  const largeIconPart = (
    <div className={classes.upperPart}>
      <div style={{ width: largeIconWidth }}>
        {largeIcon}
      </div>
    </div>
  )

  const titlePart = (
    <div className={classes.titlePart}>
      <Typography className={classes.title} style={{ textTransform: 'uppercase' }}>
        {title}
      </Typography>
    </div>
  )
  
  const bottomCard = (
    <div className={classes.bottomCard}>
      {bottomContent}
    </div>
  )

  const variants = {
    rest: {
      y: 0,
    },
    hover: {
      y: '-85%',
      transition: {
        type: 'spring', damping: 25, stiffness: 250
      }
    },
  }

  return (
    <motion.div
      initial="rest"
      whileHover="hover"
      animate="type"
      className={classes.container}>
      {isMobile &&
        (<div className={classes.innerCard}>
          {titlePart}
          {bottomCard}
        </div>
        )}
        {!isMobile &&
          <motion.div
            className={classes.innerCard}
            variants={variants}
          >
            {largeIconPart}
            {titlePart}
            {bottomCard}
          </motion.div>
        }
    </motion.div>
  );
}