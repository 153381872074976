import React from 'react';
import EmptyBackground from './EmptyBackground';


export default function EmptyBackgroundSlice({ sliceData }) {
   return (
    <EmptyBackground 
      height={sliceData.primary.height}
      backgroundColor={sliceData.primary.color}
    />
  );
}
