import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

const useStyles = makeStyles(theme => ({
  container: { 
    width: '100vw',
    height: 105,
    backgroundColor: '#090213',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
   },
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function LinearBanner({ children, animationLength }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
    <motion.div 
      animate={{ x: -animationLength}}
      transition={{duration: 100, ease: 'linear', yoyo: Infinity}}
      className={classes.innerContainer}
    >
      {children}
    </motion.div>
    </div>
  );
}