export function mapPackage(packageData) {
  const items = packageData.item.map(item => {
    return item && item.package_item && item.package_item.document && item.package_item.document.data? 
      {
        title: item.package_item.document.data.title,
        description: item.package_item.document.data.description,
        icon: item.package_item.document.data.icon,
      } : 
      {
        title: 'item',
        description: 'desc',
        icon: 'icon'
      };
  })
  return {
    title: packageData.title,
    items: items,
    subTitle: packageData.sub_title,
    description: packageData.description,
    actionButtonLabel: packageData.action_button_label,
    packageColor: packageData.package_color,
    itemHoverColor: packageData.item_hover_color,
    itemBackgroundColor: packageData.item_background_color,
    actionButtonColor: packageData.action_button_color,
    actionButtonLink: packageData.action_button_link,
    actionButtonAnchorTag: packageData.action_button_anchor_tag,
  }
}

const mapPackages = (data) => {
  return data.items.map(packageData => {
    return mapPackage(packageData.package.document.data)
  })
}

export default mapPackages;