import React from 'react';
import ContactUsFullWidth from './ContactUsFullWidth';
import ContactUsWithImage from './ContactUsWithImage';

export default function ContactUsFormSlice({ sliceData }) {

  const contactUsFormComponent = sliceData.primary.full_width ? 
    <ContactUsFullWidth /> :
    <ContactUsWithImage />

  return contactUsFormComponent;

}