import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputField: {
    height: 72,
    paddingTop: 30,
    paddingBottom: 8,
    width: '100%',
    background: 'transparent',
    border: 'none',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    letterSpacing: theme.typography.h4.letterSpacing,
    lineHeight: 2,
    borderBottom: '3px solid #090213',
    '&::placeholder': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
      letterSpacing: theme.typography.h2.letterSpacing,
      lineHeight: theme.typography.h2.lineHeight,
      color: 'black',
      opacity: 2,
    },
    '&:focus': {
      outline: 0,
    },
  },}));


export default function LargeTextInputField({ placeholder, onChange, value }) {
  const classes = useStyles();
  return (
    <div style={{height: 72}}>
      <input aria-label={placeholder} value={value} onChange={onChange} type="text" className={classes.inputField} placeholder={placeholder}>
      </input>
    </div>
  );
}