import React from 'react';
import BlogSection from '../blog-section/BlogSection';
import CodeSnippet from './CodeSnippet';

export default function CodeSnippetSlice({ sliceData }) {
  return (
    <BlogSection>
      <CodeSnippet 
        code={sliceData.primary.code.text}
        fileName={sliceData.primary.file_name}
      />
    </BlogSection>
  );
}