import React from 'react';
import mapPackages from './mapPackages';
import Packages from './Packages';

export default function PackagesSlice({ sliceData }) {
  
  // Maps data from prismic to usage for <Packages> component
  const packages = mapPackages(sliceData)
  const backgroundColor = sliceData.primary.background_color;

  return (
    <Packages 
      backgroundColor={backgroundColor}
      packages={packages}
    />
  );
}
