import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  outerSkillbar: {
    marginTop: 8,
    border: theme.custom.standardBorder,
    width: '100%',
    height: 40,
  },
  innerSkillbar: {
    width: settings => settings.percentage,
    height: '100%',
    backgroundColor: 'black'
  }
}));

export default function SkillBar({ title, level }) {
  const percentage = `${(level/10)*100}%`
  const settings = {
    percentage
  }
  const classes = useStyles(settings);
  return (
    <div className={classes.container}>
      <div>
        <Typography variant="button">
          {title}
        </Typography>
      </div>
      <div className={classes.outerSkillbar}>
        <div className={classes.innerSkillbar}>
        </div>
      </div>
    </div>
  );
}
