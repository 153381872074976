import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { motion } from "framer-motion"
import ResponsiveComponentRenderer from '../../components/stuff/ResponsiveComponentRenderer';

const useStyles = makeStyles(theme => ({
  container: { 
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 48,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: theme.custom.standardBorder,
    overflow: 'hidden', 
  },
  title: {
    paddingLeft: 32,
  }
}));


export default function PackageItem({ packageItem, backgroundColor, hoverColor}) {
  const classes = useStyles();


  const iconVariants = {
    rest: {
      x: -100,
      opacity: 0,
    },
    hover: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring', damping: 25, stiffness: 500
      },
    },
  }

  const titleVariants = {
    rest: {
      x: -64,
    },
    hover: {
      x: 0,
      transition: {
        type: 'spring', damping: 40, stiffness: 500
      },
    },
  }

  const containerVariants = {
    rest: {
      background: backgroundColor,
    },
    hover: {
      background: hoverColor,
    },
  }

  const desktopItem = 
    <motion.div
      initial="rest"
      whileHover="hover"
      animate="type"
      variants={containerVariants}
      className={classes.container}>
      <motion.div
        variants={iconVariants}
        style={{ width: 32, height: 32 }}>
        <img style={{ height: 32, width: 32 }} src={packageItem.icon.url} alt={packageItem.icon.alt}></img>
      </motion.div>
      <motion.div
        variants={titleVariants}
      >
        <div className={classes.title}>
          <Typography style={{ textTransform: 'uppercase' }} variant="h6">
            {packageItem.title}
          </Typography>
        </div>
      </motion.div>
    </motion.div>;

  const mobileItem = 
    <div
      className={classes.container}>
      <div
        style={{ width: 32, height: 32 }}>
        <img style={{ height: 32, width: 32 }} src={packageItem.icon.url} alt={packageItem.icon.alt}></img>
      </div>
      <div>
        <div className={classes.title}>
          <Typography style={{ textTransform: 'uppercase' }} variant="h6">
            {packageItem.title}
          </Typography>
        </div>
      </div>
    </div>;


  return (
    <ResponsiveComponentRenderer 
      mobileComponent={mobileItem}
      desktopComponent={desktopItem}
      size="md"
    />
  );
}
