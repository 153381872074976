import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: { 
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
  }
}));

export default function FullWidthImage({ image }) {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {image}  
      </div>
    </div>
  );
}
