import React from 'react';
import BlogSection from '../blog-section/BlogSection';
import Quote from './Quote';

export default function QuoteSlice({ sliceData }) {
  return (
    <BlogSection>
      <Quote 
        quote={sliceData.primary.quote}
      />
    </BlogSection>
  );
}
