import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import SmallAnimatedCard from '../../components/cards/SmallAnimatedCard';
import CardContentIconAndText from '../../components/cards/CardContentIconAndText';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    backgroundColor: theme.palette.primaryYellow.light1,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    width: '100%',
    marginBottom: 32,
    [theme.breakpoints.up('md')]: {
      marginBottom: 96,
      width: 378,
    },
  },
  titleBorder: {
    borderBottom: theme.custom.largeBorder,
    paddingTop: 8,
  },
}));

export default function InfoCards({ title, maxWidth, desktopHeight, mobileHeight, infoCards }) {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '82%',
          top: 96,
          bottom: 128
        }}>
        <Grid style={{ margin: 0, width: '100%' }} container spacing={6}>
          {title && (
            <Grid item xs={12}>
              <div className={classes.title}>
                <Typography style={{ textTransform: 'uppercase', display: 'inline-block', color: 'black' }} variant="h3">
                    {title}
                    <div className={classes.titleBorder}></div>
                </Typography>
              </div>
            </Grid>
          )}
          {infoCards.map(card => {
            return (
              <Grid key={card.card_title} item xs={12} lg={6} className={classes.gridItem}>
                <SmallAnimatedCard 
                  maxWidth={maxWidth}
                  desktopHeight={desktopHeight}
                  mobileHeight={mobileHeight}
                  title={card.card_title}
                  largeIcon={<img alt={card.large_image.alt} src={card.large_image.url}/>}
                  largeIconWidth={card.large_icon_width}
                  bottomContent={
                    <CardContentIconAndText
                        smallIcon={<img alt={card.small_icon.alt} src={card.small_icon.url} />}
                        smallIconWidth={card.small_icon_width}
                        htmlText={card.card_text_content.html}
                    />}
                />
              </Grid>
            )
          })}
        </Grid>
      </ResponsiveMarginContainer>
    </section>
  );
}
