import React from 'react';

export default function SnabbaSvg({size}) {
  return (
    <svg width="330" height="62" viewBox="0 0 330 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="330" height="62" fill="black">
        <rect fill="white" width="330" height="62" />
        <path d="M26.8542 59C21.7966 59 17.495 58.2741 13.9495 56.8222C10.4039 55.3704 7.71871 53.4519 5.89379 51.0667C4.06888 48.6815 3.10428 46.0889 3 43.2889C3 42.8222 3.15642 42.4333 3.46926 42.1222C3.83425 41.8111 4.25137 41.6556 4.72063 41.6556H15.1227C15.7483 41.6556 16.2437 41.7593 16.6087 41.9667C17.0258 42.1741 17.469 42.537 17.9382 43.0556C18.6161 44.3 19.6589 45.337 21.0667 46.1667C22.5266 46.9444 24.4558 47.3333 26.8542 47.3333C29.6698 47.3333 31.8337 46.9185 33.3457 46.0889C34.8578 45.2074 35.6138 44.0148 35.6138 42.5111C35.6138 41.4222 35.2228 40.5407 34.4407 39.8667C33.6586 39.1407 32.4072 38.5185 30.6866 38C28.9659 37.4296 26.4893 36.8074 23.2566 36.1333C16.9476 34.8889 12.2289 32.9963 9.10043 30.4556C5.972 27.9148 4.40779 24.2852 4.40779 19.5667C4.40779 16.3519 5.29418 13.5 7.06695 11.0111C8.89187 8.47037 11.4468 6.5 14.7316 5.1C18.0686 3.7 21.927 3 26.3068 3C30.8951 3 34.8578 3.8037 38.1948 5.41111C41.5839 6.96667 44.1649 8.93704 45.9376 11.3222C47.7626 13.6556 48.7272 15.963 48.8314 18.2444C48.8314 18.7111 48.675 19.1259 48.3622 19.4889C48.0493 19.8 47.6583 19.9556 47.189 19.9556H36.2395C35.0924 19.9556 34.206 19.4889 33.5804 18.5556C33.2675 17.4667 32.4593 16.5593 31.1558 15.8333C29.8523 15.0556 28.236 14.6667 26.3068 14.6667C24.1169 14.6667 22.4223 15.0556 21.2231 15.8333C20.0238 16.6111 19.4242 17.7519 19.4242 19.2556C19.4242 20.7593 20.2063 21.9519 21.7706 22.8333C23.3348 23.663 26.2546 24.5185 30.5301 25.4C35.4314 26.2815 39.3158 27.3963 42.1835 28.7444C45.1034 30.0407 47.2412 31.7519 48.5968 33.8778C50.0046 35.9519 50.7085 38.5963 50.7085 41.8111C50.7085 45.337 49.6918 48.3963 47.6583 50.9889C45.6248 53.5815 42.8092 55.5778 39.2115 56.9778C35.6138 58.3259 31.4947 59 26.8542 59Z" />
        <path d="M59.3585 58.2222C58.8371 58.2222 58.3678 58.0407 57.9507 57.6778C57.5857 57.263 57.4032 56.7963 57.4032 56.2778V5.8C57.4032 5.22963 57.5857 4.76296 57.9507 4.4C58.3157 3.98518 58.785 3.77778 59.3585 3.77778H68.1963C69.3955 3.77778 70.2819 4.27037 70.8555 5.25555L89.3132 34.2667V5.8C89.3132 5.22963 89.4957 4.76296 89.8607 4.4C90.2256 3.98518 90.6949 3.77778 91.2684 3.77778H101.201C101.775 3.77778 102.244 3.98518 102.609 4.4C102.974 4.76296 103.156 5.22963 103.156 5.8V56.2C103.156 56.7704 102.974 57.263 102.609 57.6778C102.244 58.0407 101.775 58.2222 101.201 58.2222H92.3634C91.1642 58.2222 90.2778 57.7296 89.7042 56.7444L71.3247 28.9V56.2778C71.3247 56.8481 71.1162 57.3148 70.699 57.6778C70.3341 58.0407 69.8648 58.2222 69.2913 58.2222H59.3585Z" />
        <path d="M109.905 58.2222C109.488 58.2222 109.097 58.0667 108.732 57.7556C108.419 57.3926 108.263 57.0037 108.263 56.5889C108.263 56.2778 108.289 56.0444 108.341 55.8889L126.564 6.03334C127.085 4.52963 128.128 3.77778 129.692 3.77778H141.893C143.457 3.77778 144.5 4.52963 145.022 6.03334L163.245 55.8889C163.297 56.0444 163.323 56.2778 163.323 56.5889C163.323 57.0037 163.14 57.3926 162.775 57.7556C162.463 58.0667 162.098 58.2222 161.68 58.2222H151.513C150.262 58.2222 149.375 57.6519 148.854 56.5111L146.038 48.9667H125.547L122.732 56.5111C122.21 57.6519 121.324 58.2222 120.072 58.2222H109.905ZM128.832 37.3H142.753L135.793 17.4667L128.832 37.3Z" />
        <path d="M170.386 58.2222C169.865 58.2222 169.395 58.0407 168.978 57.6778C168.613 57.263 168.431 56.7963 168.431 56.2778V5.8C168.431 5.22963 168.613 4.76296 168.978 4.4C169.343 3.98518 169.812 3.77778 170.386 3.77778H194.553C201.175 3.77778 206.102 5.12593 209.335 7.82222C212.568 10.5185 214.184 14.3556 214.184 19.3333C214.184 21.8222 213.506 23.9741 212.151 25.7889C210.847 27.6037 209.387 28.9259 207.771 29.7556C209.908 30.6889 211.707 32.2444 213.167 34.4222C214.627 36.5481 215.357 38.9593 215.357 41.6556C215.357 46.8926 213.61 50.963 210.117 53.8667C206.676 56.7704 201.722 58.2222 195.257 58.2222H170.386ZM192.911 25.3222C194.84 25.3222 196.326 24.8037 197.369 23.7667C198.412 22.7296 198.933 21.3815 198.933 19.7222C198.933 18.1148 198.412 16.8185 197.369 15.8333C196.378 14.7963 194.892 14.2778 192.911 14.2778H183.134V25.3222H192.911ZM193.615 47.7222C195.648 47.7222 197.212 47.1519 198.307 46.0111C199.454 44.8185 200.028 43.3667 200.028 41.6556C200.028 39.8926 199.454 38.4407 198.307 37.3C197.16 36.1074 195.596 35.5111 193.615 35.5111H183.134V47.7222H193.615Z" />
        <path d="M224.869 58.2222C224.347 58.2222 223.878 58.0407 223.461 57.6778C223.096 57.263 222.913 56.7963 222.913 56.2778V5.8C222.913 5.22963 223.096 4.76296 223.461 4.4C223.826 3.98518 224.295 3.77778 224.869 3.77778H249.036C255.658 3.77778 260.585 5.12593 263.818 7.82222C267.05 10.5185 268.667 14.3556 268.667 19.3333C268.667 21.8222 267.989 23.9741 266.633 25.7889C265.33 27.6037 263.87 28.9259 262.253 29.7556C264.391 30.6889 266.19 32.2444 267.65 34.4222C269.11 36.5481 269.84 38.9593 269.84 41.6556C269.84 46.8926 268.093 50.963 264.6 53.8667C261.158 56.7704 256.205 58.2222 249.74 58.2222H224.869ZM247.393 25.3222C249.323 25.3222 250.809 24.8037 251.851 23.7667C252.894 22.7296 253.416 21.3815 253.416 19.7222C253.416 18.1148 252.894 16.8185 251.851 15.8333C250.861 14.7963 249.375 14.2778 247.393 14.2778H237.617V25.3222H247.393ZM248.097 47.7222C250.131 47.7222 251.695 47.1519 252.79 46.0111C253.937 44.8185 254.511 43.3667 254.511 41.6556C254.511 39.8926 253.937 38.4407 252.79 37.3C251.643 36.1074 250.079 35.5111 248.097 35.5111H237.617V47.7222H248.097Z" />
        <path d="M273.582 58.2222C273.165 58.2222 272.774 58.0667 272.409 57.7556C272.096 57.3926 271.94 57.0037 271.94 56.5889C271.94 56.2778 271.966 56.0444 272.018 55.8889L290.241 6.03334C290.762 4.52963 291.805 3.77778 293.369 3.77778H305.57C307.135 3.77778 308.177 4.52963 308.699 6.03334L326.922 55.8889C326.974 56.0444 327 56.2778 327 56.5889C327 57.0037 326.818 57.3926 326.453 57.7556C326.14 58.0667 325.775 58.2222 325.358 58.2222H315.19C313.939 58.2222 313.052 57.6519 312.531 56.5111L309.715 48.9667H289.224L286.409 56.5111C285.887 57.6519 285.001 58.2222 283.75 58.2222H273.582ZM292.509 37.3H306.431L299.47 17.4667L292.509 37.3Z" />
      </mask>
      <path d="M26.8542 59C21.7966 59 17.495 58.2741 13.9495 56.8222C10.4039 55.3704 7.71871 53.4519 5.89379 51.0667C4.06888 48.6815 3.10428 46.0889 3 43.2889C3 42.8222 3.15642 42.4333 3.46926 42.1222C3.83425 41.8111 4.25137 41.6556 4.72063 41.6556H15.1227C15.7483 41.6556 16.2437 41.7593 16.6087 41.9667C17.0258 42.1741 17.469 42.537 17.9382 43.0556C18.6161 44.3 19.6589 45.337 21.0667 46.1667C22.5266 46.9444 24.4558 47.3333 26.8542 47.3333C29.6698 47.3333 31.8337 46.9185 33.3457 46.0889C34.8578 45.2074 35.6138 44.0148 35.6138 42.5111C35.6138 41.4222 35.2228 40.5407 34.4407 39.8667C33.6586 39.1407 32.4072 38.5185 30.6866 38C28.9659 37.4296 26.4893 36.8074 23.2566 36.1333C16.9476 34.8889 12.2289 32.9963 9.10043 30.4556C5.972 27.9148 4.40779 24.2852 4.40779 19.5667C4.40779 16.3519 5.29418 13.5 7.06695 11.0111C8.89187 8.47037 11.4468 6.5 14.7316 5.1C18.0686 3.7 21.927 3 26.3068 3C30.8951 3 34.8578 3.8037 38.1948 5.41111C41.5839 6.96667 44.1649 8.93704 45.9376 11.3222C47.7626 13.6556 48.7272 15.963 48.8314 18.2444C48.8314 18.7111 48.675 19.1259 48.3622 19.4889C48.0493 19.8 47.6583 19.9556 47.189 19.9556H36.2395C35.0924 19.9556 34.206 19.4889 33.5804 18.5556C33.2675 17.4667 32.4593 16.5593 31.1558 15.8333C29.8523 15.0556 28.236 14.6667 26.3068 14.6667C24.1169 14.6667 22.4223 15.0556 21.2231 15.8333C20.0238 16.6111 19.4242 17.7519 19.4242 19.2556C19.4242 20.7593 20.2063 21.9519 21.7706 22.8333C23.3348 23.663 26.2546 24.5185 30.5301 25.4C35.4314 26.2815 39.3158 27.3963 42.1835 28.7444C45.1034 30.0407 47.2412 31.7519 48.5968 33.8778C50.0046 35.9519 50.7085 38.5963 50.7085 41.8111C50.7085 45.337 49.6918 48.3963 47.6583 50.9889C45.6248 53.5815 42.8092 55.5778 39.2115 56.9778C35.6138 58.3259 31.4947 59 26.8542 59Z" stroke="#090213" strokeWidth="6" mask="url(#path-1-outside-1)" />
      <path d="M59.3585 58.2222C58.8371 58.2222 58.3678 58.0407 57.9507 57.6778C57.5857 57.263 57.4032 56.7963 57.4032 56.2778V5.8C57.4032 5.22963 57.5857 4.76296 57.9507 4.4C58.3157 3.98518 58.785 3.77778 59.3585 3.77778H68.1963C69.3955 3.77778 70.2819 4.27037 70.8555 5.25555L89.3132 34.2667V5.8C89.3132 5.22963 89.4957 4.76296 89.8607 4.4C90.2256 3.98518 90.6949 3.77778 91.2684 3.77778H101.201C101.775 3.77778 102.244 3.98518 102.609 4.4C102.974 4.76296 103.156 5.22963 103.156 5.8V56.2C103.156 56.7704 102.974 57.263 102.609 57.6778C102.244 58.0407 101.775 58.2222 101.201 58.2222H92.3634C91.1642 58.2222 90.2778 57.7296 89.7042 56.7444L71.3247 28.9V56.2778C71.3247 56.8481 71.1162 57.3148 70.699 57.6778C70.3341 58.0407 69.8648 58.2222 69.2913 58.2222H59.3585Z" stroke="#090213" strokeWidth="6" mask="url(#path-1-outside-1)" />
      <path d="M109.905 58.2222C109.488 58.2222 109.097 58.0667 108.732 57.7556C108.419 57.3926 108.263 57.0037 108.263 56.5889C108.263 56.2778 108.289 56.0444 108.341 55.8889L126.564 6.03334C127.085 4.52963 128.128 3.77778 129.692 3.77778H141.893C143.457 3.77778 144.5 4.52963 145.022 6.03334L163.245 55.8889C163.297 56.0444 163.323 56.2778 163.323 56.5889C163.323 57.0037 163.14 57.3926 162.775 57.7556C162.463 58.0667 162.098 58.2222 161.68 58.2222H151.513C150.262 58.2222 149.375 57.6519 148.854 56.5111L146.038 48.9667H125.547L122.732 56.5111C122.21 57.6519 121.324 58.2222 120.072 58.2222H109.905ZM128.832 37.3H142.753L135.793 17.4667L128.832 37.3Z" stroke="#090213" strokeWidth="6" mask="url(#path-1-outside-1)" />
      <path d="M170.386 58.2222C169.865 58.2222 169.395 58.0407 168.978 57.6778C168.613 57.263 168.431 56.7963 168.431 56.2778V5.8C168.431 5.22963 168.613 4.76296 168.978 4.4C169.343 3.98518 169.812 3.77778 170.386 3.77778H194.553C201.175 3.77778 206.102 5.12593 209.335 7.82222C212.568 10.5185 214.184 14.3556 214.184 19.3333C214.184 21.8222 213.506 23.9741 212.151 25.7889C210.847 27.6037 209.387 28.9259 207.771 29.7556C209.908 30.6889 211.707 32.2444 213.167 34.4222C214.627 36.5481 215.357 38.9593 215.357 41.6556C215.357 46.8926 213.61 50.963 210.117 53.8667C206.676 56.7704 201.722 58.2222 195.257 58.2222H170.386ZM192.911 25.3222C194.84 25.3222 196.326 24.8037 197.369 23.7667C198.412 22.7296 198.933 21.3815 198.933 19.7222C198.933 18.1148 198.412 16.8185 197.369 15.8333C196.378 14.7963 194.892 14.2778 192.911 14.2778H183.134V25.3222H192.911ZM193.615 47.7222C195.648 47.7222 197.212 47.1519 198.307 46.0111C199.454 44.8185 200.028 43.3667 200.028 41.6556C200.028 39.8926 199.454 38.4407 198.307 37.3C197.16 36.1074 195.596 35.5111 193.615 35.5111H183.134V47.7222H193.615Z" stroke="#090213" strokeWidth="6" mask="url(#path-1-outside-1)" />
      <path d="M224.869 58.2222C224.347 58.2222 223.878 58.0407 223.461 57.6778C223.096 57.263 222.913 56.7963 222.913 56.2778V5.8C222.913 5.22963 223.096 4.76296 223.461 4.4C223.826 3.98518 224.295 3.77778 224.869 3.77778H249.036C255.658 3.77778 260.585 5.12593 263.818 7.82222C267.05 10.5185 268.667 14.3556 268.667 19.3333C268.667 21.8222 267.989 23.9741 266.633 25.7889C265.33 27.6037 263.87 28.9259 262.253 29.7556C264.391 30.6889 266.19 32.2444 267.65 34.4222C269.11 36.5481 269.84 38.9593 269.84 41.6556C269.84 46.8926 268.093 50.963 264.6 53.8667C261.158 56.7704 256.205 58.2222 249.74 58.2222H224.869ZM247.393 25.3222C249.323 25.3222 250.809 24.8037 251.851 23.7667C252.894 22.7296 253.416 21.3815 253.416 19.7222C253.416 18.1148 252.894 16.8185 251.851 15.8333C250.861 14.7963 249.375 14.2778 247.393 14.2778H237.617V25.3222H247.393ZM248.097 47.7222C250.131 47.7222 251.695 47.1519 252.79 46.0111C253.937 44.8185 254.511 43.3667 254.511 41.6556C254.511 39.8926 253.937 38.4407 252.79 37.3C251.643 36.1074 250.079 35.5111 248.097 35.5111H237.617V47.7222H248.097Z" stroke="#090213" strokeWidth="6" mask="url(#path-1-outside-1)" />
      <path d="M273.582 58.2222C273.165 58.2222 272.774 58.0667 272.409 57.7556C272.096 57.3926 271.94 57.0037 271.94 56.5889C271.94 56.2778 271.966 56.0444 272.018 55.8889L290.241 6.03334C290.762 4.52963 291.805 3.77778 293.369 3.77778H305.57C307.135 3.77778 308.177 4.52963 308.699 6.03334L326.922 55.8889C326.974 56.0444 327 56.2778 327 56.5889C327 57.0037 326.818 57.3926 326.453 57.7556C326.14 58.0667 325.775 58.2222 325.358 58.2222H315.19C313.939 58.2222 313.052 57.6519 312.531 56.5111L309.715 48.9667H289.224L286.409 56.5111C285.887 57.6519 285.001 58.2222 283.75 58.2222H273.582ZM292.509 37.3H306.431L299.47 17.4667L292.509 37.3Z" stroke="#090213" strokeWidth="6" mask="url(#path-1-outside-1)" />
    </svg>
  );
}
