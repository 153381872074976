import React from 'react';
import LinearBanner from '../../components/effects/LinearBanner';
import ContactWhiteFilledSvg from '../../components/icons/contactWhiteFilledSvg';
import ContactWhiteOutlinedSvg from '../../components/icons/contactWhiteOutlinedSvg';


export default function ContactBanner() {
  return (
    <div id="contact_form">
      <LinearBanner animationLength={3000}>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteFilledSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteOutlinedSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteFilledSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteOutlinedSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteFilledSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteOutlinedSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteFilledSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteOutlinedSvg />
        </div>
        <div style={{ marginLeft: 100 }}>
          <ContactWhiteFilledSvg />
        </div>
      </LinearBanner>
    </div>
  );
}