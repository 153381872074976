import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: settings => settings.backgroundColor,
    height: settings => settings.height
  },}));

export default function EmptyBackground({ height, backgroundColor }) {
  const settings = {
    backgroundColor: backgroundColor,
    height: height
  }
  const classes = useStyles(settings); 

   return (
    <div className={classes.container} />
  );
}
