import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import { Grid } from '@material-ui/core';
import ContactUsForm from '../../components/forms/contactUsForm';
import imageSource from '../../images/dagverket-kontakta-oss-bild.png'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    backgroundColor: theme.palette.primaryYellow.light1,
  },
  left: {
    border: null,
    marginBottom: 48,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      borderTop: theme.custom.standardBorder,
      borderRight: theme.custom.standardBorder,
      borderLeft: 0,
      borderBottom: 0,
    },
  },
  right: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: theme.custom.standardBorder,
    [theme.breakpoints.up('md')]: {
      borderTop: theme.custom.standardBorder,
      borderLeft: 0,
      borderBottom: 0,
      borderRight: 0,
    },
  },
  image: {
    margin: 0,
    maxHeight: 200
  }
}));

export default function ContactUsWithImage() {
  const classes = useStyles();
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('md'));

  return (
    <section className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '96%',
          top: 48,
          bottom: 0,
        }}
        mobileSizes={{
          width: '94%',
          top: 16,
          bottom: 0,
        }}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12} md={7} lg={6} className={classes.left}>
            <ContactUsForm />
          </Grid>
          {isDesktop &&
            (<Grid item xs={null} md={5} lg={6} className={classes.right}>
              <img className={classes.image} src={imageSource} alt="Hej Hand" />
            </Grid>
            )}
        </Grid>
      </ResponsiveMarginContainer>
    </section>
  );
}
