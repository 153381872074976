import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useTheme } from '@material-ui/core';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    backgroundColor: settings => settings.backgroundColor,
  },
  largeTextContainer: {
    alignSelf: 'center',
  },
  largeText: {
    color: '#010713',
    fontFamily: 'Rubik, helvetica, sans-serif',
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: 1.167,
    [theme.breakpoints.up('md')]: {
      fontWeight: 'bold',
      fontSize: 76,
      lineHeight: 1.2,
    },
  },
  gridSection: {
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 553px)',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'minmax(200px, 553px) minmax(200px, 553px)',
    },
    gridRowGap: 48,
    gridColumnGap: 24,
  },
  title: {
    gridColumn: '1 / 2',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / 3',
    },
  },
  titleBorder: {
    borderBottom: theme.custom.largeBorder,
    paddingTop: 8,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    maxWidth: settings => settings.imageMaxWidth,
  },
  columnTextContainer: {
    columnGap: 30,
    columnCount: 1,
    gridColumn: '1 / 2',
    [theme.breakpoints.up('md')]: {
      gridColumn: settings => settings.gridColumn,
      columnCount: settings => settings.columnCount,
    },
    '& p:first-child': {
      margin: 0,
      marginBlockStart: 0,
      marginInlineStart: 0,
    },
  },
}));

export default function HeroTextIcon({ title, backgroundColor, largeText, image, imageMaxWidth, htmlInfoText, useTwoCols }) {

  const theme = useTheme()

  const settings = {
    imageMaxWidth: imageMaxWidth ? imageMaxWidth : 550,
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.primaryYellow.main,
    gridColumn: useTwoCols === true ? '1 / 3' : '1 / 2',
    columnCount: useTwoCols === true ? 2 : 1,
  }

  const classes = useStyles(settings);

  return (
    <section className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '76%',
          top: 192, //navbar height + 128 padding
          bottom: 48,
        }}
        mobileSizes={{
          width: '88%',
          top: 130,
          bottom: 32,
        }}>
        <div className={classes.gridSection}>

          <div className={classes.title}>
            <Typography style={{ display: 'inline-block', color: 'black', textTransform: 'uppercase' }} variant="h3">
              {title}
              <div className={classes.titleBorder} />
            </Typography>
          </div>

          <div className={classes.largeTextContainer}>
            <Typography className={classes.largeText}>{largeText}</Typography>
          </div>

          <div className={classes.imageContainer}>
            <Img className={classes.image} fluid={image.fluid}/>
          </div>

          <div className={classes.columnTextContainer}>
            <HtmlTextRenderer>
              {htmlInfoText}
            </HtmlTextRenderer>
          </div>

        </div>
      </ResponsiveMarginContainer>
    </section>
  );
}
