import React from 'react';
import SkillCards from './SkillCards'

export default function SkillsCardSlice({ sliceData }) {
  return (
    <SkillCards 
      maxWidth={sliceData.primary.skills_card_max_width}
      desktopHeight={sliceData.primary.skills_card_desktop_height}
      mobileHeight={sliceData.primary.skills_card_mobile_height} 
      skillCards={sliceData.items}
    />
  );
}
