import React from 'react';
import TextWithPrimaryButton from './TextWithPrimaryButton';


export default function TextWithPrimaryButtonSlice({ sliceData }) {
  return (
    <TextWithPrimaryButton 
      largeText={sliceData.primary.large_text}
      backgroundColor={sliceData.primary.background_color}
      infoTextHtml={sliceData.primary.info_text.html}
      buttonLabel={sliceData.primary.button_label}
      buttonAnchorTag={sliceData.primary.button_anchor_tag}
      buttonPath={sliceData.primary.button_link.url}
    />
  );
}
