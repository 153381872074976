import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  quotationMark: {
    paddingBottom: 32,
  },
  innerContainer: {
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
  }
}));

export default function Quote({ quote }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.quotationMark}>  
          <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3978 0L10.3123 15.0704H15.2565V30H0V16.4789L7.34573 0H15.3978ZM38 0L32.9145 15.0704H37.8587V30H22.6022V16.4789L29.948 0H38Z" fill="black" />
          </svg>
        </div>
        <Typography variant="h3">
          {quote}
        </Typography>
      </div>
    </div>
  );
}
