import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputField: {
    padding: 19,
    paddingLeft: 24,
    fontFamily: 'Cabin',
    fontSize: 18,
    width: '100%',
    background: theme.palette.primaryYellow.light1,
    border: theme.custom.standardBorder,
    '&::placeholder': {
      textTransform: 'uppercase',
      fontFamily: 'Cabin',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 1.25,
      color: 'grey',
      opacity: 1,
    },
    '&:focus': {
      outline: 0,
    },
  },
}));

export default function PrimaryTextFieldInput({ placeholder, onChange, value }) {
  const classes = useStyles();
  return (
    <input value={value} onChange={onChange} type="text" className={classes.inputField} placeholder={placeholder}>
    </input>
  );
}