import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';
import PrimaryButtonLink from '../../components/input/button/PrimaryButtonLink';


const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: settings => settings.backgroundColor
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    columnGap: 32,
  },
  largeTextContainer: {
    fontSize: 48,
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '130%',
    [theme.breakpoints.up('md')]: {
      fontSize: 72,
    }
  },
  infoTextContainer: {
    paddingRight: 32,
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: null,
    },
    display: 'flex',
    flexDirection: 'column',
  }
}));

export default function TextWithPrimaryButton({ 
                          largeText,
                          backgroundColor,
                          infoTextHtml,
                          buttonLabel,
                          buttonAnchorTag,
                          buttonPath }){
  const settings = {
    backgroundColor: backgroundColor
  }
  const classes = useStyles(settings);
  return (
    <div className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '84%',
          top: 32,
          bottom: 96,
        }}
        mobileSizes={{
          width: '92%',
          top: 32,
          bottom: 64,
        }}>
        <div className={classes.grid}>
          <div className={classes.largeTextContainer}>  
            {largeText}
          </div>
          <div className={classes.infoTextContainer}>
            <div>
              <HtmlTextRenderer>
                {infoTextHtml}
              </HtmlTextRenderer>
            </div>
            {buttonLabel && (
              <div style={{ paddingTop: 16 }}>
                <PrimaryButtonLink label={buttonLabel} to={buttonPath} anchorTag={buttonAnchorTag}/>
              </div>
            )}
          </div>
        </div>
      </ResponsiveMarginContainer>
    </div>
  );
}
