import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import Package from '../packages/Package';
import PackageTextContainer from './PackageTextContainer';

const useStyles = makeStyles(theme => ({
  container: { 
    paddingTop: 32,
    paddingBottom: 32,
    width: '100%',
    backgroundColor: settings => settings.backgroundColor,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    gridTemplateRows: '1fr',
    gap: '0px 0px',
  },
  packageItemWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  }
}));

export default function OnePackage({ 
  backgroundColor, 
  onePackage, 
  title, 
  packageSide, 
  largeText, 
  infoText,
  buttonLabel,
  buttonPath }) 
{
  
  const settings = {
    backgroundColor: backgroundColor
  }
  const classes = useStyles(settings);
  
  const packageTextContainer = <PackageTextContainer 
    buttonLabel={buttonLabel} 
    buttonPath={buttonPath} 
    title={title} 
    largeText={largeText} 
    infoText={infoText} />

  return (
    <div className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '90%',
          top: 96,
          bottom: 64,
        }}
        mobileSizes={{
          width: '90%',
          top: 12,
          bottom: 12,
        }}>
          <div className={classes.grid}>
          {packageSide === 'right' && packageTextContainer}
           <div className={classes.packageItemWrapper}>
              <Package packageData={onePackage}/>
            </div>
          {packageSide === 'left' && packageTextContainer}
          </div>
        </ResponsiveMarginContainer>
    </div>
  );
}
