import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InternalLink from '../../stuff/InternalLink';
import { motion } from 'framer-motion';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
    boxShadow: settings => settings.boxShadow,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
    padding: settings => settings.paddingMobile,
    [theme.breakpoints.up('lg')]: {
      padding: settings => settings.paddingDesktop,
    },
    backgroundColor: theme.palette.primaryYellow.main,
    border: theme.custom.standardBorder,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 0,
    },
  },
  flex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: settings => settings.fontSizeMobile,
    [theme.breakpoints.up('lg')]: {
      fontSize: settings => settings.fontSizeDesktop,
    },
    color: 'black',
  }
}));

export default function PrimaryButtonLink({ label, to, anchorTag, size}) {

  let settings = {
    paddingDesktop: '24px 32px',
    paddingMobile: '24px 32px',
    fontSizeDesktop: 16,
    fontSizeMobile: 16,
    boxShadow: 'none',
  };
  switch(size) {
    case 'huge': {
      settings = {
        paddingMobile: '32px 50px',
        paddingDesktop: '32px 132px',
        fontSizeDesktop: 52,
        fontSizeMobile: 32,
        boxShadow: '10px 10px 0px rgba(0, 0, 0, 1)',
      }
      break;
    }
    default: {
      break;
    }
  }

  const classes = useStyles(settings);

  return (
    <InternalLink to={to} anchorTag={anchorTag}>
      <motion.div 
          className={classes.button}
          whileHover={{
            scale: 1.1
          }}
          whileTap={{ scale: 0.9 }}>
        <div className={classes.flex}>
          <Typography className={classes.buttonText} variant="button">
            {label}
          </Typography>
        </div>
      </motion.div>
    </InternalLink>
  );
}