import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 16,
    paddingLeft: 74,
    paddingRight: 74,
    backgroundColor: theme.palette.primaryYellow.main,
    border: theme.custom.standardBorder,
    boxShadow: '16px 16px 0px #15042A',
    transition: '0.3s',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 0,
    },
  },
  buttonText: {
    color: 'black',
   }
}));

export default function HardShadowButton({ label, onClick }) {
  const classes = useStyles();
  return (
    <button className={classes.button} onClick={onClick}>
      <Typography className={classes.buttonText} variant="button">
        {label}
      </Typography>
    </button>
  );
}

/**
 * Type definitions
 */
HardShadowButton.propTypes = {
  label: PropTypes.string.isRequired,
};
