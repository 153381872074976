import React from 'react';
import Img from 'gatsby-image';
import FullWidthImage from './FullWidthImage';
import BlogSection from '../blog-section/BlogSection';

export default function FullWidthImageSlice({ sliceData }) {
  return (
    <BlogSection>
      <FullWidthImage 
        image={<Img 
          objectFit="cover"
          alt={sliceData.primary.image.alt} 
          fluid={sliceData.primary.image.fluid}
        />}
      />
    </BlogSection>
  );
}
