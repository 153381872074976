import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LargeTextInputField from '../input/text/largeTextInputField';
import HardShadowButton from '../input/button/HardShadowButton';
import { Typography, CircularProgress } from '@material-ui/core';
import TextAreaInputField from '../input/text/TextAreaInputField';
import thumbsUpPng from '../../images/dagsverket-tummen-upp-ikon.png'

const useStyles = makeStyles(theme => ({
  container: { 
    width: '100%',
    height: '100%',
    minHeight: 615,
    display: 'flex',
    flexDirection: 'column'
   },
  messageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
  inputContainer: {
    paddingTop: 48,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 64,
  },
  inputField: {
    marginBottom: 32,
  },
  sendButtom: {
    marginTop: 64,
  },
  image: {
    margin: 0,
    width: '100%',
    maxWidth: 221,
    paddingBottom: 48,
  }
}));

export default function ContactUsForm({ howToHelpField }) {
  const classes = useStyles();

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [website, setWebsite] = useState('')
  const [howCanWeHelp, setHowCanWeHelp] = useState('')
  const [sent, setSent] = useState('NOT_SENT');

  const submit = async () => {
    setSent('LOADING')
    try {
      const data = {
        name: name.trim(),
        email: email.trim(),
        company: company.trim(),
        website: website.trim(),
        howCanWeHelp: howCanWeHelp.trim()
      };
      const response = await fetch('https://6yj28vsa1e.execute-api.eu-west-1.amazonaws.com/prod/contact',
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
      if (response && response.status === 200) {
        setSent('DONE')
      } else {
        setSent('FAILED')
      }
    } catch (error) {
      setSent('FAILED')
    }
  }


  let contactFormComponent;

  switch (sent) {
    case 'NOT_SENT':
      contactFormComponent = (
          <div className={classes.inputContainer}>
            <div className={classes.inputField}>
              <LargeTextInputField placeholder="Namn" value={name} onChange={(event) => setName(event.target.value)} />
            </div>
            <div className={classes.inputField}>
              <LargeTextInputField placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
            </div>
            <div className={classes.inputField}>
              <LargeTextInputField placeholder="Företag" value={company} onChange={(event) => setCompany(event.target.value)} />
            </div>
            <div>
              <LargeTextInputField placeholder="Hemsida" value={website} onChange={(event) => setWebsite(event.target.value)} />
            </div>
            {howToHelpField && 
              <div className={classes.inputField}>
                <TextAreaInputField placeholder="Mer info" value={howCanWeHelp} onChange={(event) => setHowCanWeHelp(event.target.value)} />
              </div>
            }
            <div className={classes.sendButtom}>
              <HardShadowButton onClick={submit} label="Skicka" />
            </div>
          </div>
        );
      break;
    case 'DONE':
      contactFormComponent = (
        <div className={classes.messageContainer}>
          <img className={classes.image} src={thumbsUpPng} alt="Tummen upp" />
          <Typography variant="h2">Tack!</Typography>
          <Typography variant="h4">Vi kontaktar dig inom kort.</Typography>
        </div>
        );
      break;
    case 'FAILED':
      contactFormComponent = 
      (
        <div className={classes.messageContainer}>
          <Typography color="error" variant="h4">Något gick fel :(</Typography>
        </div>
      );
      break;
    case 'LOADING':
      contactFormComponent = 
      (
        <div className={classes.messageContainer}>
          <CircularProgress style={{ color: 'black' }} size={100} />
        </div>
      )
      break;
    default:
      contactFormComponent = 
      (
        <div className={classes.messageContainer}>
          <Typography color="error" variant="h2">Error...</Typography>
        </div>
      )
      break;
  }


  return (
    <div className={classes.container}>
      {contactFormComponent}
    </div>
  );
}
