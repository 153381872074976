import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextBanderoll from '../../components/effects/TextBanderoll';

const useStyles = makeStyles(theme => ({
  container: { 
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.primaryPink.main,
  },
  pinkSection: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '30%',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '8%',
    }
  },
  heroText: {
    textTransform: 'uppercase',
    color: 'black',
    paddingLeft: 12,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 64,
    }
  },
}));

export default function HeroBannerAnimationSlice() {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <div className={classes.pinkSection}>
        <div className={classes.textSection}>
          <Typography variant="h1" className={classes.heroText}>Vi gör</Typography>
          <TextBanderoll />
          <Typography variant="h1" className={classes.heroText}>Hemsidor</Typography>
        </div>
      </div>
    </section>
  );
}