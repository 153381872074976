import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Package from './Package';

const useStyles = makeStyles(theme => ({
  container: { 
    paddingTop: 32,
    paddingBottom: 32,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: settings => settings.backgroundColor,
  },
  innerContainer: {
    width: '90%',
    maxWidth: 1440,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'flex-start',
    },
  }
}));

export default function Packages({ backgroundColor, packages }) {
  
  const settings = {
    backgroundColor: backgroundColor
  }

  const classes = useStyles(settings);
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {packages.map(packageData => {
          return <Package key={packageData.title} packageData={packageData} />
        })}
      </div>
    </div>
  );
}
