import React from 'react';
import Img from "gatsby-image"
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import SmallAnimatedCard from '../../components/cards/SmallAnimatedCard';
import CardContentSkillz from '../../components/cards/CardContentSkillz';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    backgroundColor: theme.palette.primaryYellow.light1,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  gridItem: {
    justifySelf: 'center',
    alignSelf: 'center',
    paddingBottom: 64,
  }
}));

export default function SkillCards({ maxWidth, desktopHeight, mobileHeight, skillCards }) {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '86%',
          top: 128,
          bottom: 64,
        }}
        mobileSizes={{
          width: '88%',
          top: 96,
          bottom: 0,
        }}>
        <div className={classes.grid}>
          
          {skillCards.map(card => {
            return (
              <div className={classes.gridItem}>
                
                <SmallAnimatedCard
                  title={card.skills_card_title}
                  maxWidth={maxWidth}
                  desktopHeight={desktopHeight}
                  mobileHeight={mobileHeight}
                  largeIcon={<Img style={{width: 200}} fluid={card.skills_card_image.fluid}/>}
                  largeIconWidth={272}
                  bottomContent={<CardContentSkillz
                    htmlText={card.skills_card_text_content.html}
                    skillz={[
                      {
                        title: card.skill_1_title,
                        level: card.skill_1_level
                      },
                      {
                        title: card.skill_2_title,
                        level: card.skill_2_level
                      },
                      {
                        title: card.skill_3_title,
                        level: card.skill_3_level
                      },
                    ]}
                  />}
                />

              </div>
            )
          })}
        </div>
      </ResponsiveMarginContainer>
    </section>
  );
}
