 import React from 'react';
 import { makeStyles } from '@material-ui/core/styles';
 import { Typography } from '@material-ui/core';
import ResponsiveMarginContainer from '../../../components/containers/responsiveMarginContainer';
import HtmlTextRenderer from '../../../prismic/components/HtmlTextRenderer';
 
 const useStyles = makeStyles(theme => ({
   container: {
     width: '100%',
     backgroundColor: settings => settings.backgroundColor,
    },
    textContent: {
      columnCount: 1,
      [theme.breakpoints.up('md')]: {
        columnCount: settings => settings.columnCount,
      },
      '& p:first-child': {
        margin: 0,
        marginBlockStart: 0,
        marginInlineStart: 0,
      },
    }
 }));
 
  export default function TextArea({ htmlText, headline, numberOfColumns, backgroundColor }) {
   const settings = {
     backgroundColor: backgroundColor? backgroundColor : 'transparent',
     columnCount: numberOfColumns
   };

   const classes = useStyles(settings);
   return (
     <div className={classes.container}>
       <ResponsiveMarginContainer
         desktopSizes={{
           width: '70%',
           top: 0,
           bottom: 0,
         }}
         mobileSizes={{
           width: '88%',
           top: 0,
           bottom: 0,
         }}
       >
      {headline && 
        <Typography style={{paddingBottom: 24}} variant="h4">
          {headline}
        </Typography>
      }
       <div className={classes.textContent}>
          <HtmlTextRenderer>
            {htmlText}
          </HtmlTextRenderer>
       </div>
       </ResponsiveMarginContainer>
     </div>
   );
 }
