import React from 'react';

export default function SnyggaSvg({size}) {
  return (
    <>
      {size === 'small' && (
        <svg width="320" height="56" viewBox="0 0 320 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.8067 56C18.7591 56 14.4661 55.2741 10.9277 53.8222C7.38918 52.3704 4.7093 50.4519 2.88802 48.0667C1.06675 45.6815 0.104073 43.0889 0 40.2889C0 39.8222 0.156109 39.4333 0.468328 39.1222C0.832583 38.8111 1.24887 38.6556 1.7172 38.6556H12.0985C12.7229 38.6556 13.2173 38.7593 13.5815 38.9667C13.9978 39.1741 14.4401 39.537 14.9084 40.0556C15.5849 41.3 16.6256 42.337 18.0306 43.1667C19.4877 43.9444 21.413 44.3333 23.8067 44.3333C26.6166 44.3333 28.7762 43.9185 30.2852 43.0889C31.7943 42.2074 32.5488 41.0148 32.5488 39.5111C32.5488 38.4222 32.1585 37.5407 31.378 36.8667C30.5974 36.1407 29.3486 35.5185 27.6314 35C25.9142 34.4296 23.4424 33.8074 20.2162 33.1333C13.9198 31.8889 9.21045 29.9963 6.08827 27.4556C2.96608 24.9148 1.40498 21.2852 1.40498 16.5667C1.40498 13.3519 2.2896 10.5 4.05884 8.01111C5.88012 5.47037 8.42991 3.5 11.7082 2.1C15.0385 0.7 18.8892 0 23.2603 0C27.8395 0 31.7943 0.803704 35.1246 2.41111C38.507 3.96667 41.0828 5.93704 42.852 8.32222C44.6733 10.6556 45.636 12.963 45.74 15.2444C45.74 15.7111 45.5839 16.1259 45.2717 16.4889C44.9595 16.8 44.5692 16.9556 44.1009 16.9556H33.1732C32.0284 16.9556 31.1438 16.4889 30.5194 15.5556C30.2072 14.4667 29.4006 13.5593 28.0997 12.8333C26.7988 12.0556 25.1856 11.6667 23.2603 11.6667C21.0748 11.6667 19.3836 12.0556 18.1867 12.8333C16.9899 13.6111 16.3915 14.7519 16.3915 16.2556C16.3915 17.7593 17.172 18.9519 18.7331 19.8333C20.2942 20.663 23.2083 21.5185 27.4753 22.4C32.3667 23.2815 36.2434 24.3963 39.1054 25.7444C42.0194 27.0407 44.1529 28.7519 45.5059 30.8778C46.9109 32.9519 47.6134 35.5963 47.6134 38.8111C47.6134 42.337 46.5986 45.3963 44.5692 47.9889C42.5398 50.5815 39.7298 52.5778 36.1393 53.9778C32.5488 55.3259 28.4379 56 23.8067 56Z" fill="#090213" />
          <path d="M56.2461 55.2222C55.7258 55.2222 55.2574 55.0407 54.8411 54.6778C54.4769 54.263 54.2948 53.7963 54.2948 53.2778V2.8C54.2948 2.22963 54.4769 1.76296 54.8411 1.4C55.2054 0.985185 55.6737 0.777777 56.2461 0.777777H65.0663C66.2631 0.777777 67.1478 1.27037 67.7202 2.25555L86.1411 31.2667V2.8C86.1411 2.22963 86.3232 1.76296 86.6874 1.4C87.0517 0.985185 87.52 0.777777 88.0924 0.777777H98.0054C98.5778 0.777777 99.0461 0.985185 99.4104 1.4C99.7746 1.76296 99.9567 2.22963 99.9567 2.8V53.2C99.9567 53.7704 99.7746 54.263 99.4104 54.6778C99.0461 55.0407 98.5778 55.2222 98.0054 55.2222H89.1852C87.9884 55.2222 87.1037 54.7296 86.5313 53.7444L68.1885 25.9V53.2778C68.1885 53.8481 67.9803 54.3148 67.564 54.6778C67.1998 55.0407 66.7315 55.2222 66.1591 55.2222H56.2461Z" fill="#090213" />
          <path d="M125.737 55.2222C125.217 55.2222 124.748 55.0407 124.332 54.6778C123.968 54.263 123.786 53.7963 123.786 53.2778V36.7111L105.755 3.26667C105.599 3.00741 105.521 2.72222 105.521 2.41111C105.521 1.9963 105.677 1.63333 105.989 1.32222C106.354 0.959259 106.744 0.777777 107.16 0.777777H117.463C118.192 0.777777 118.79 0.959259 119.259 1.32222C119.727 1.68519 120.065 2.07407 120.273 2.48889L131.123 22.0111L141.973 2.48889C142.181 2.07407 142.519 1.68519 142.987 1.32222C143.456 0.959259 144.054 0.777777 144.782 0.777777H155.086C155.502 0.777777 155.866 0.959259 156.178 1.32222C156.543 1.63333 156.725 1.9963 156.725 2.41111C156.725 2.72222 156.647 3.00741 156.491 3.26667L138.46 36.7111V53.2778C138.46 53.8481 138.252 54.3148 137.836 54.6778C137.471 55.0407 137.003 55.2222 136.431 55.2222H125.737Z" fill="#090213" />
          <path d="M182.635 56C175.09 56 169.184 54.1852 164.917 50.5556C160.65 46.8741 158.386 41.637 158.126 34.8444C158.074 33.3926 158.048 31.0333 158.048 27.7667C158.048 24.5 158.074 22.1148 158.126 20.6111C158.334 14.0778 160.598 9.02222 164.917 5.44445C169.288 1.81482 175.194 0 182.635 0C187.631 0 191.976 0.803704 195.67 2.41111C199.417 4.01852 202.253 6.09259 204.178 8.63333C206.156 11.1222 207.196 13.6889 207.3 16.3333C207.3 16.8 207.118 17.2148 206.754 17.5778C206.442 17.8889 206.052 18.0444 205.583 18.0444H193.485C192.964 18.0444 192.548 17.9667 192.236 17.8111C191.976 17.6037 191.715 17.3185 191.455 16.9556C190.831 15.5037 189.816 14.2593 188.411 13.2222C187.058 12.1852 185.133 11.6667 182.635 11.6667C176.599 11.6667 173.477 14.7778 173.269 21C173.217 22.4519 173.191 24.6815 173.191 27.6889C173.191 30.6963 173.217 32.9519 173.269 34.4556C173.477 41.0407 176.651 44.3333 182.791 44.3333C185.809 44.3333 188.203 43.5815 189.972 42.0778C191.742 40.5741 192.626 38.2926 192.626 35.2333V33.8333H185.679C185.107 33.8333 184.613 33.6519 184.196 33.2889C183.832 32.8741 183.65 32.3815 183.65 31.8111V25.7444C183.65 25.1741 183.832 24.7074 184.196 24.3444C184.613 23.9296 185.107 23.7222 185.679 23.7222H205.505C206.078 23.7222 206.546 23.9296 206.91 24.3444C207.326 24.7074 207.535 25.1741 207.535 25.7444V34.7667C207.535 39.1741 206.494 42.9852 204.412 46.2C202.383 49.363 199.495 51.8 195.748 53.5111C192.002 55.1704 187.631 56 182.635 56Z" fill="#090213" />
          <path d="M238.229 56C230.684 56 224.777 54.1852 220.51 50.5556C216.243 46.8741 213.98 41.637 213.72 34.8444C213.668 33.3926 213.642 31.0333 213.642 27.7667C213.642 24.5 213.668 22.1148 213.72 20.6111C213.928 14.0778 216.191 9.02222 220.51 5.44445C224.881 1.81482 230.788 0 238.229 0C243.224 0 247.569 0.803704 251.264 2.41111C255.011 4.01852 257.847 6.09259 259.772 8.63333C261.749 11.1222 262.79 13.6889 262.894 16.3333C262.894 16.8 262.712 17.2148 262.348 17.5778C262.035 17.8889 261.645 18.0444 261.177 18.0444H249.078C248.558 18.0444 248.142 17.9667 247.829 17.8111C247.569 17.6037 247.309 17.3185 247.049 16.9556C246.425 15.5037 245.41 14.2593 244.005 13.2222C242.652 12.1852 240.727 11.6667 238.229 11.6667C232.193 11.6667 229.07 14.7778 228.862 21C228.81 22.4519 228.784 24.6815 228.784 27.6889C228.784 30.6963 228.81 32.9519 228.862 34.4556C229.07 41.0407 232.245 44.3333 238.385 44.3333C241.403 44.3333 243.797 43.5815 245.566 42.0778C247.335 40.5741 248.22 38.2926 248.22 35.2333V33.8333H241.273C240.701 33.8333 240.206 33.6519 239.79 33.2889C239.426 32.8741 239.243 32.3815 239.243 31.8111V25.7444C239.243 25.1741 239.426 24.7074 239.79 24.3444C240.206 23.9296 240.701 23.7222 241.273 23.7222H261.099C261.671 23.7222 262.14 23.9296 262.504 24.3444C262.92 24.7074 263.128 25.1741 263.128 25.7444V34.7667C263.128 39.1741 262.087 42.9852 260.006 46.2C257.977 49.363 255.089 51.8 251.342 53.5111C247.595 55.1704 243.224 56 238.229 56Z" fill="#090213" />
          <path d="M266.689 55.2222C266.272 55.2222 265.882 55.0667 265.518 54.7556C265.206 54.3926 265.05 54.0037 265.05 53.5889C265.05 53.2778 265.076 53.0444 265.128 52.8889L283.314 3.03334C283.835 1.52963 284.875 0.777777 286.436 0.777777H298.613C300.174 0.777777 301.215 1.52963 301.735 3.03334L319.922 52.8889C319.974 53.0444 320 53.2778 320 53.5889C320 54.0037 319.818 54.3926 319.454 54.7556C319.141 55.0667 318.777 55.2222 318.361 55.2222H308.214C306.965 55.2222 306.08 54.6519 305.56 53.5111L302.75 45.9667H282.3L279.49 53.5111C278.969 54.6519 278.085 55.2222 276.836 55.2222H266.689ZM285.578 34.3H299.472L292.525 14.4667L285.578 34.3Z" fill="#090213" />
        </svg>
      )}
      {size === 'large' && (
        <svg width="320" height="56" viewBox="0 0 320 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.8067 56C18.7591 56 14.4661 55.2741 10.9277 53.8222C7.38918 52.3704 4.7093 50.4519 2.88802 48.0667C1.06675 45.6815 0.104073 43.0889 0 40.2889C0 39.8222 0.156109 39.4333 0.468328 39.1222C0.832583 38.8111 1.24887 38.6556 1.7172 38.6556H12.0985C12.7229 38.6556 13.2173 38.7593 13.5815 38.9667C13.9978 39.1741 14.4401 39.537 14.9084 40.0556C15.5849 41.3 16.6256 42.337 18.0306 43.1667C19.4877 43.9444 21.413 44.3333 23.8067 44.3333C26.6166 44.3333 28.7762 43.9185 30.2852 43.0889C31.7943 42.2074 32.5488 41.0148 32.5488 39.5111C32.5488 38.4222 32.1585 37.5407 31.378 36.8667C30.5974 36.1407 29.3486 35.5185 27.6314 35C25.9142 34.4296 23.4424 33.8074 20.2162 33.1333C13.9198 31.8889 9.21045 29.9963 6.08827 27.4556C2.96608 24.9148 1.40498 21.2852 1.40498 16.5667C1.40498 13.3519 2.2896 10.5 4.05884 8.01111C5.88012 5.47037 8.42991 3.5 11.7082 2.1C15.0385 0.7 18.8892 0 23.2603 0C27.8395 0 31.7943 0.803704 35.1246 2.41111C38.507 3.96667 41.0828 5.93704 42.852 8.32222C44.6733 10.6556 45.636 12.963 45.74 15.2444C45.74 15.7111 45.5839 16.1259 45.2717 16.4889C44.9595 16.8 44.5692 16.9556 44.1009 16.9556H33.1732C32.0284 16.9556 31.1438 16.4889 30.5194 15.5556C30.2072 14.4667 29.4006 13.5593 28.0997 12.8333C26.7988 12.0556 25.1856 11.6667 23.2603 11.6667C21.0748 11.6667 19.3836 12.0556 18.1867 12.8333C16.9899 13.6111 16.3915 14.7519 16.3915 16.2556C16.3915 17.7593 17.172 18.9519 18.7331 19.8333C20.2942 20.663 23.2083 21.5185 27.4753 22.4C32.3667 23.2815 36.2434 24.3963 39.1054 25.7444C42.0194 27.0407 44.1529 28.7519 45.5059 30.8778C46.9109 32.9519 47.6134 35.5963 47.6134 38.8111C47.6134 42.337 46.5986 45.3963 44.5692 47.9889C42.5398 50.5815 39.7298 52.5778 36.1393 53.9778C32.5488 55.3259 28.4379 56 23.8067 56Z" fill="#090213" />
          <path d="M56.2461 55.2222C55.7258 55.2222 55.2574 55.0407 54.8411 54.6778C54.4769 54.263 54.2948 53.7963 54.2948 53.2778V2.8C54.2948 2.22963 54.4769 1.76296 54.8411 1.4C55.2054 0.985185 55.6737 0.777777 56.2461 0.777777H65.0663C66.2631 0.777777 67.1478 1.27037 67.7202 2.25555L86.1411 31.2667V2.8C86.1411 2.22963 86.3232 1.76296 86.6874 1.4C87.0517 0.985185 87.52 0.777777 88.0924 0.777777H98.0054C98.5778 0.777777 99.0461 0.985185 99.4104 1.4C99.7746 1.76296 99.9567 2.22963 99.9567 2.8V53.2C99.9567 53.7704 99.7746 54.263 99.4104 54.6778C99.0461 55.0407 98.5778 55.2222 98.0054 55.2222H89.1852C87.9884 55.2222 87.1037 54.7296 86.5313 53.7444L68.1885 25.9V53.2778C68.1885 53.8481 67.9803 54.3148 67.564 54.6778C67.1998 55.0407 66.7315 55.2222 66.1591 55.2222H56.2461Z" fill="#090213" />
          <path d="M125.737 55.2222C125.217 55.2222 124.748 55.0407 124.332 54.6778C123.968 54.263 123.786 53.7963 123.786 53.2778V36.7111L105.755 3.26667C105.599 3.00741 105.521 2.72222 105.521 2.41111C105.521 1.9963 105.677 1.63333 105.989 1.32222C106.354 0.959259 106.744 0.777777 107.16 0.777777H117.463C118.192 0.777777 118.79 0.959259 119.259 1.32222C119.727 1.68519 120.065 2.07407 120.273 2.48889L131.123 22.0111L141.973 2.48889C142.181 2.07407 142.519 1.68519 142.987 1.32222C143.456 0.959259 144.054 0.777777 144.782 0.777777H155.086C155.502 0.777777 155.866 0.959259 156.178 1.32222C156.543 1.63333 156.725 1.9963 156.725 2.41111C156.725 2.72222 156.647 3.00741 156.491 3.26667L138.46 36.7111V53.2778C138.46 53.8481 138.252 54.3148 137.836 54.6778C137.471 55.0407 137.003 55.2222 136.431 55.2222H125.737Z" fill="#090213" />
          <path d="M182.635 56C175.09 56 169.184 54.1852 164.917 50.5556C160.65 46.8741 158.386 41.637 158.126 34.8444C158.074 33.3926 158.048 31.0333 158.048 27.7667C158.048 24.5 158.074 22.1148 158.126 20.6111C158.334 14.0778 160.598 9.02222 164.917 5.44445C169.288 1.81482 175.194 0 182.635 0C187.631 0 191.976 0.803704 195.67 2.41111C199.417 4.01852 202.253 6.09259 204.178 8.63333C206.156 11.1222 207.196 13.6889 207.3 16.3333C207.3 16.8 207.118 17.2148 206.754 17.5778C206.442 17.8889 206.052 18.0444 205.583 18.0444H193.485C192.964 18.0444 192.548 17.9667 192.236 17.8111C191.976 17.6037 191.715 17.3185 191.455 16.9556C190.831 15.5037 189.816 14.2593 188.411 13.2222C187.058 12.1852 185.133 11.6667 182.635 11.6667C176.599 11.6667 173.477 14.7778 173.269 21C173.217 22.4519 173.191 24.6815 173.191 27.6889C173.191 30.6963 173.217 32.9519 173.269 34.4556C173.477 41.0407 176.651 44.3333 182.791 44.3333C185.809 44.3333 188.203 43.5815 189.972 42.0778C191.742 40.5741 192.626 38.2926 192.626 35.2333V33.8333H185.679C185.107 33.8333 184.613 33.6519 184.196 33.2889C183.832 32.8741 183.65 32.3815 183.65 31.8111V25.7444C183.65 25.1741 183.832 24.7074 184.196 24.3444C184.613 23.9296 185.107 23.7222 185.679 23.7222H205.505C206.078 23.7222 206.546 23.9296 206.91 24.3444C207.326 24.7074 207.535 25.1741 207.535 25.7444V34.7667C207.535 39.1741 206.494 42.9852 204.412 46.2C202.383 49.363 199.495 51.8 195.748 53.5111C192.002 55.1704 187.631 56 182.635 56Z" fill="#090213" />
          <path d="M238.229 56C230.684 56 224.777 54.1852 220.51 50.5556C216.243 46.8741 213.98 41.637 213.72 34.8444C213.668 33.3926 213.642 31.0333 213.642 27.7667C213.642 24.5 213.668 22.1148 213.72 20.6111C213.928 14.0778 216.191 9.02222 220.51 5.44445C224.881 1.81482 230.788 0 238.229 0C243.224 0 247.569 0.803704 251.264 2.41111C255.011 4.01852 257.847 6.09259 259.772 8.63333C261.749 11.1222 262.79 13.6889 262.894 16.3333C262.894 16.8 262.712 17.2148 262.348 17.5778C262.035 17.8889 261.645 18.0444 261.177 18.0444H249.078C248.558 18.0444 248.142 17.9667 247.829 17.8111C247.569 17.6037 247.309 17.3185 247.049 16.9556C246.425 15.5037 245.41 14.2593 244.005 13.2222C242.652 12.1852 240.727 11.6667 238.229 11.6667C232.193 11.6667 229.07 14.7778 228.862 21C228.81 22.4519 228.784 24.6815 228.784 27.6889C228.784 30.6963 228.81 32.9519 228.862 34.4556C229.07 41.0407 232.245 44.3333 238.385 44.3333C241.403 44.3333 243.797 43.5815 245.566 42.0778C247.335 40.5741 248.22 38.2926 248.22 35.2333V33.8333H241.273C240.701 33.8333 240.206 33.6519 239.79 33.2889C239.426 32.8741 239.243 32.3815 239.243 31.8111V25.7444C239.243 25.1741 239.426 24.7074 239.79 24.3444C240.206 23.9296 240.701 23.7222 241.273 23.7222H261.099C261.671 23.7222 262.14 23.9296 262.504 24.3444C262.92 24.7074 263.128 25.1741 263.128 25.7444V34.7667C263.128 39.1741 262.087 42.9852 260.006 46.2C257.977 49.363 255.089 51.8 251.342 53.5111C247.595 55.1704 243.224 56 238.229 56Z" fill="#090213" />
          <path d="M266.689 55.2222C266.272 55.2222 265.882 55.0667 265.518 54.7556C265.206 54.3926 265.05 54.0037 265.05 53.5889C265.05 53.2778 265.076 53.0444 265.128 52.8889L283.314 3.03334C283.835 1.52963 284.875 0.777777 286.436 0.777777H298.613C300.174 0.777777 301.215 1.52963 301.735 3.03334L319.922 52.8889C319.974 53.0444 320 53.2778 320 53.5889C320 54.0037 319.818 54.3926 319.454 54.7556C319.141 55.0667 318.777 55.2222 318.361 55.2222H308.214C306.965 55.2222 306.08 54.6519 305.56 53.5111L302.75 45.9667H282.3L279.49 53.5111C278.969 54.6519 278.085 55.2222 276.836 55.2222H266.689ZM285.578 34.3H299.472L292.525 14.4667L285.578 34.3Z" fill="#090213" />
        </svg>
      )}
    </>

  );
}
