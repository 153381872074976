import React from 'react';
import HeroTextIcon from './HeroTextIcon';

export default function HeroTextIconSlice({ sliceData }) {
  return (
    <HeroTextIcon 
      title={sliceData.primary.title} 
      backgroundColor={sliceData.primary.background_color} 
      largeText={sliceData.primary.large_text} 
      htmlInfoText={sliceData.primary.info_text.html}
      useTwoCols={sliceData.primary.info_text_double_columns}
      imageMaxWidth={sliceData.primary.image_max_width} 
      image={sliceData.primary.image} 
      />

  );
}
