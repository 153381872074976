import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';
import InternalLink from '../../components/stuff/InternalLink';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    backgroundColor: theme.palette.primaryYellow.main,
  },
  text: {
    color: theme.palette.font.dark
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: 48,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
    }
  },
  listItem: {
    fontFamily: 'Rubik, helvetica, sans-serif',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.334,
    letterSpacing: '0em',
    [theme.breakpoints.up('md')]: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
  }
}));

export default function LargeTextWithListSlice({ sliceData }) {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '72%',
          top: 96,
          bottom: 96
        }}
        mobileSizes={{
          top: 64,
          bottom: 64,
          width: '88%'
        }}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <div style={{ maxWidth: 644, height: '100%' }}>
              <Typography className={classes.text} variant="h2">
                {sliceData.primary.large_text_with_list}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.listContainer}>
            {sliceData.items.map(link => {
              return (
                <InternalLink key={link.display_name} to={link.service_page_link.url}>
                  <Typography className={classes.listItem}>
                    {link.display_name}
                  </Typography>
                </InternalLink>
                )
            })}
          </Grid>
        </Grid>
      </ResponsiveMarginContainer>
    </section>
  );
}
