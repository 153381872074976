import React from 'react';
import LightHouseTest from './LightHouseTest';

export default function LightHouseTestSlice({ sliceData }) {
  return (
    <LightHouseTest
      title={sliceData.primary.title}
      infoText={sliceData.primary.info_text.html}
    />
  );
}
