import React from 'react';
import LargeTitle from './LargeTitle';

export default function LargeTitleSlice({ sliceData }) {
  return (
    <LargeTitle 
      title={sliceData.primary.title} 
      backgroundColor={sliceData.primary.background_color} 
      align={sliceData.primary.align} 
      underline={sliceData.primary.underline} 
      uppercase={sliceData.primary.uppercase} 
    />
  );
}
