import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import Prism from "prismjs";
import '../../../themes/prism-code-theme_2.css'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#2a2734',
  },
  innerContainer: {
    position: 'relative',
    alignSelf: 'center',
    paddingTop: 24,
    paddingBottom: 16,
    paddingLeft: 32,
    width: '96%',
    [theme.breakpoints.up('md')]: {
      width: '66%',
      borderLeft: '1px solid #3d3d3d',
      borderRight: '1px solid #3d3d3d',
    },
  },
  langTag: {
    position: 'absolute',
    top: 0,
    left: 32,
    fontFamily: 'Inconsolata, Monaco, Consolas, Courier, monospace',
    fontWeight: 'bold',
    color: '#2a2734',
    fontSize: 12,
    padding: '5px 15px 5px 15px',
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: theme.palette.primaryYellow.main,
  },
  code: {
    paddingTop: 16,
    paddingBottom: 24,
  }
}));

export default function CodeSnippet({ code, fileName }) {
  const classes = useStyles();

  useEffect(() => {
    Prism.highlightAll();
  })

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
          <span className={`${classes.langTag}`}>
            {fileName}
          </span>
        <div className={classes.code}>
          <ReactMarkdown source={code} />
        </div>
      </div>
    </div>
  );
}
