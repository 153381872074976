import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ResponsiveMarginContainer from '../../components/containers/responsiveMarginContainer';

const useStyles = makeStyles(theme => ({
  container: { 
    width: '100%',
    backgroundColor: settings => settings.backgroundColor
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: settings => settings.flexAlign
  },
  titleBorder: {
    borderBottom: theme.custom.largeBorder,
    paddingTop: 8,
  },
}));

export default function LargeTitle({ title, backgroundColor, align, underline, uppercase}) {
  
  let flexAlign;
  switch(align) {
    case 'left': 
      flexAlign = 'flex-start';
      break;
    case 'center':
      flexAlign = 'center';
      break;
    case 'right':
      flexAlign = 'flex-end';
      break;
    default:
      flexAlign = 'flex-start';
      break;
  }

  const settings = {
    backgroundColor: backgroundColor,
    flexAlign: flexAlign,
  }
  const textTransform = uppercase === true? 'uppercase' : 'none';

  const classes = useStyles(settings);
  return (
    <div className={classes.container}>      
      <ResponsiveMarginContainer
        desktopSizes={{
          width: '84%',
          top: 64,
          bottom: 32,
        }}
        mobileSizes={{
          width: '92%',
          top: 64,
          bottom: 16,
        }}>
        <div className={classes.titleContainer}>
          <Typography style={{ textTransform: textTransform, display: 'inline-block', color: '#090213' }} variant="h3">
            {title}
            {underline === true &&
              <div className={classes.titleBorder}></div>
            }
          </Typography>
        </div>
      </ResponsiveMarginContainer>
    </div>
  );
}
