import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    maxWidth: settings => settings.maxWidth,
  }
}));

export default function CenteredImage({ image, maxWidth }) {
  const settings = {
    maxWidth: maxWidth? maxWidth : 1000,
  }
  const classes = useStyles(settings);

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {image}  
      </div>
    </div>
  );
}
