
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';
import PrimaryButtonLink from '../../components/input/button/PrimaryButtonLink';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primaryPink.main,
  },
  innerContainer: {
    maxWidth: 1440,
  },
  grid: {
    height: '100%',
    paddingTop: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      paddingTop: '20%',
      gridTemplateColumns: '6fr 5fr',
      gridColumnGap: 0,
    }
  },
  textContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  buttonContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    }
  },
  largeText: {
    maxWidth: 550,
    fontFamily: 'Rubik, Helvetica, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 32,
    [theme.breakpoints.up('md')]: {
      fontSize: 96,
    },
  },
}));

export default function HugeButtonHero({ largeText, infoText, buttonLabel, buttonLink }) {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.grid}>
          <div className={classes.textContainer}>
            <div className={classes.largeText}>
              {largeText}
            </div>
            <Typography variant="h6">
              <HtmlTextRenderer>
                {infoText.html}
              </HtmlTextRenderer>
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <PrimaryButtonLink size="huge" label={buttonLabel} to={buttonLink.url} />
          </div>
        </div>
      </div>
    </section>
  );
}
