
import React from 'react';
import HugeButtonHero from './HugeButtonHero';

export default function HugeButtonHeroSlice({ sliceData }) {

  return (
    <HugeButtonHero
      largeText={sliceData.primary.large_text}
      infoText={sliceData.primary.info_text}
      buttonLabel={sliceData.primary.button_label}
      buttonLink={sliceData.primary.button_link}
    />
  );
}
