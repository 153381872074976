import React from 'react';
import CenteredImage from './CenteredImage';
import Img from 'gatsby-image';
import BlogSection from '../blog-section/BlogSection';

export default function CenteredImageSlice({ sliceData }) {
  return (
    <BlogSection>
      <CenteredImage 
        image={<Img alt={sliceData.primary.image.alt} fluid={sliceData.primary.image.fluid}/>}
        maxWidth={sliceData.primary.max_width}
      />
    </BlogSection>
  );
}
