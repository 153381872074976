import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';
import { Typography } from '@material-ui/core';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => ({
  itemWrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginLeft: 60,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 128,
    },
  },
  card: {
    zIndex: 1,
    borderRadius: 20,
    padding: 20,
  },
  icon: {
    zIndex: 0,
    position: 'absolute',
    opacity: 0.1,
    top: -30,
    left: -60,
    [theme.breakpoints.up('lg')]: {
      top: -37,
      left: -78,
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  image: {
    height: 100, 
    width: 100,
    [theme.breakpoints.up('lg')]: {
      height: 130,
      width: 130,
    },
  }
}));

export default function PackageItemWithDescription({ icon, title, description }) {

  const classes = useStyles();
  
  return (
    <div className={classes.itemWrapper}>
      <div className={classes.icon}>
        <div>
          {/* <img className={classes.image} src={iconUrl} alt={iconAlt}></img> */}
          <Img className={classes.image} fluid={icon.fluid} />
        </div>
      </div>
      <div
       className={classes.card}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <div>
          <HtmlTextRenderer>
            {description && description.html}
          </HtmlTextRenderer>
        </div>
      </div>
    </div>
  );
}
