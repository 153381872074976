import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {motion} from 'framer-motion';
import InternalLink from '../../components/stuff/InternalLink';

const useStyles = makeStyles(theme => ({
  actionButton: {
    width: '100%',
    backgroundColor: settings => settings.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 26,
    paddingTop: 26,
    '&:hover': {
      cursor: 'pointer',
    }
  }}));

export default function PackageActionButton({ label, backgroundColor, link, anchorTag }) {
  const settings = {
    backgroundColor: backgroundColor,
  }
  const classes = useStyles(settings);

  const variants = {
    rest: {
      scale: 1,
    },
    hover: {
      scale: 1.1,
    },
    tap: {
      scale: 0.9,
    }
  }

  return (
    <InternalLink to={link.url} anchorTag={anchorTag}>
      <motion.div
        initial="rest"
        whileHover="hover"
        whileTap="tap"
        className={classes.actionButton}>
          <motion.div
            variants={variants}
          >
            <Typography variant="button">
              {label}
            </Typography>
          </motion.div>
      </motion.div>
    </InternalLink>
  );
}
