import React from 'react';

export default function SynligaSvg({size}) {
  return (
    <>
    {size === 'small' && (
      <svg width="336" height="56" viewBox="0 0 336 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.8422 56C18.7871 56 14.4877 55.2741 10.944 53.8222C7.40021 52.3704 4.71633 50.4519 2.89233 48.0667C1.06834 45.6815 0.104228 43.0889 0 40.2889C0 39.8222 0.156342 39.4333 0.469027 39.1222C0.833826 38.8111 1.25074 38.6556 1.71977 38.6556H12.1165C12.7419 38.6556 13.237 38.7593 13.6018 38.9667C14.0187 39.1741 14.4617 39.537 14.9307 40.0556C15.6082 41.3 16.6505 42.337 18.0575 43.1667C19.5167 43.9444 21.445 44.3333 23.8422 44.3333C26.6564 44.3333 28.8191 43.9185 30.3304 43.0889C31.8417 42.2074 32.5974 41.0148 32.5974 39.5111C32.5974 38.4222 32.2065 37.5407 31.4248 36.8667C30.6431 36.1407 29.3924 35.5185 27.6726 35C25.9528 34.4296 23.4774 33.8074 20.2463 33.1333C13.9405 31.8889 9.2242 29.9963 6.09735 27.4556C2.97051 24.9148 1.40708 21.2852 1.40708 16.5667C1.40708 13.3519 2.29302 10.5 4.0649 8.01111C5.8889 5.47037 8.44249 3.5 11.7257 2.1C15.061 0.7 18.9174 0 23.295 0C27.8811 0 31.8417 0.803704 35.177 2.41111C38.5645 3.96667 41.1441 5.93704 42.916 8.32222C44.74 10.6556 45.7041 12.963 45.8083 15.2444C45.8083 15.7111 45.652 16.1259 45.3393 16.4889C45.0266 16.8 44.6358 16.9556 44.1667 16.9556H33.2228C32.0763 16.9556 31.1903 16.4889 30.5649 15.5556C30.2523 14.4667 29.4445 13.5593 28.1416 12.8333C26.8388 12.0556 25.2232 11.6667 23.295 11.6667C21.1062 11.6667 19.4125 12.0556 18.2139 12.8333C17.0153 13.6111 16.416 14.7519 16.416 16.2556C16.416 17.7593 17.1977 18.9519 18.7611 19.8333C20.3245 20.663 23.2429 21.5185 27.5163 22.4C32.415 23.2815 36.2975 24.3963 39.1638 25.7444C42.0822 27.0407 44.2188 28.7519 45.5738 30.8778C46.9809 32.9519 47.6844 35.5963 47.6844 38.8111C47.6844 42.337 46.6682 45.3963 44.6358 47.9889C42.6033 50.5815 39.7891 52.5778 36.1933 53.9778C32.5974 55.3259 28.4804 56 23.8422 56Z" fill="black" />
        <path d="M68.1107 55.2222C67.5896 55.2222 67.1206 55.0407 66.7037 54.6778C66.3389 54.263 66.1564 53.7963 66.1564 53.2778V36.7111L48.0989 3.26667C47.9426 3.00741 47.8644 2.72222 47.8644 2.41111C47.8644 1.9963 48.0207 1.63333 48.3334 1.32222C48.6982 0.959259 49.0891 0.777777 49.506 0.777777H59.8246C60.5542 0.777777 61.1535 0.959259 61.6225 1.32222C62.0915 1.68519 62.4303 2.07407 62.6387 2.48889L73.5045 22.0111L84.3703 2.48889C84.5788 2.07407 84.9175 1.68519 85.3866 1.32222C85.8556 0.959259 86.4549 0.777777 87.1845 0.777777H97.5031C97.92 0.777777 98.2848 0.959259 98.5975 1.32222C98.9623 1.63333 99.1447 1.9963 99.1447 2.41111C99.1447 2.72222 99.0665 3.00741 98.9102 3.26667L80.8526 36.7111V53.2778C80.8526 53.8481 80.6442 54.3148 80.2273 54.6778C79.8625 55.0407 79.3934 55.2222 78.8202 55.2222H68.1107Z" fill="black" />
        <path d="M106.663 55.2222C106.142 55.2222 105.673 55.0407 105.256 54.6778C104.891 54.263 104.709 53.7963 104.709 53.2778V2.8C104.709 2.22963 104.891 1.76296 105.256 1.4C105.621 0.985185 106.09 0.777777 106.663 0.777777H115.496C116.695 0.777777 117.581 1.27037 118.154 2.25555L136.603 31.2667V2.8C136.603 2.22963 136.785 1.76296 137.15 1.4C137.515 0.985185 137.984 0.777777 138.557 0.777777H148.485C149.058 0.777777 149.527 0.985185 149.892 1.4C150.256 1.76296 150.439 2.22963 150.439 2.8V53.2C150.439 53.7704 150.256 54.263 149.892 54.6778C149.527 55.0407 149.058 55.2222 148.485 55.2222H139.651C138.453 55.2222 137.567 54.7296 136.993 53.7444L118.623 25.9V53.2778C118.623 53.8481 118.415 54.3148 117.998 54.6778C117.633 55.0407 117.164 55.2222 116.591 55.2222H106.663Z" fill="black" />
        <path d="M162.187 55.2222C161.666 55.2222 161.197 55.0407 160.78 54.6778C160.415 54.263 160.233 53.7963 160.233 53.2778V2.72222C160.233 2.15185 160.415 1.68519 160.78 1.32222C161.197 0.959259 161.666 0.777777 162.187 0.777777H173.131C173.704 0.777777 174.173 0.959259 174.538 1.32222C174.903 1.68519 175.085 2.15185 175.085 2.72222V43.0111H198.537C199.11 43.0111 199.579 43.2185 199.944 43.6333C200.361 43.9963 200.569 44.463 200.569 45.0333V53.2778C200.569 53.8481 200.361 54.3148 199.944 54.6778C199.579 55.0407 199.11 55.2222 198.537 55.2222H162.187Z" fill="black" />
        <path d="M208.245 55.2222C207.724 55.2222 207.255 55.0407 206.838 54.6778C206.473 54.263 206.291 53.7963 206.291 53.2778V2.72222C206.291 2.15185 206.473 1.68519 206.838 1.32222C207.255 0.959259 207.724 0.777777 208.245 0.777777H219.423C219.997 0.777777 220.466 0.959259 220.83 1.32222C221.195 1.68519 221.378 2.15185 221.378 2.72222V53.2778C221.378 53.7963 221.195 54.263 220.83 54.6778C220.466 55.0407 219.997 55.2222 219.423 55.2222H208.245Z" fill="black" />
        <path d="M254.107 56C246.55 56 240.635 54.1852 236.362 50.5556C232.088 46.8741 229.822 41.637 229.561 34.8444C229.509 33.3926 229.483 31.0333 229.483 27.7667C229.483 24.5 229.509 22.1148 229.561 20.6111C229.769 14.0778 232.036 9.02222 236.362 5.44445C240.739 1.81482 246.654 0 254.107 0C259.11 0 263.461 0.803704 267.161 2.41111C270.914 4.01852 273.754 6.09259 275.682 8.63333C277.662 11.1222 278.705 13.6889 278.809 16.3333C278.809 16.8 278.626 17.2148 278.262 17.5778C277.949 17.8889 277.558 18.0444 277.089 18.0444H264.973C264.451 18.0444 264.034 17.9667 263.722 17.8111C263.461 17.6037 263.201 17.3185 262.94 16.9556C262.315 15.5037 261.298 14.2593 259.891 13.2222C258.536 12.1852 256.608 11.6667 254.107 11.6667C248.061 11.6667 244.935 14.7778 244.726 21C244.674 22.4519 244.648 24.6815 244.648 27.6889C244.648 30.6963 244.674 32.9519 244.726 34.4556C244.935 41.0407 248.114 44.3333 254.263 44.3333C257.286 44.3333 259.683 43.5815 261.455 42.0778C263.227 40.5741 264.113 38.2926 264.113 35.2333V33.8333H257.155C256.582 33.8333 256.087 33.6519 255.67 33.2889C255.305 32.8741 255.123 32.3815 255.123 31.8111V25.7444C255.123 25.1741 255.305 24.7074 255.67 24.3444C256.087 23.9296 256.582 23.7222 257.155 23.7222H277.011C277.584 23.7222 278.053 23.9296 278.418 24.3444C278.835 24.7074 279.043 25.1741 279.043 25.7444V34.7667C279.043 39.1741 278.001 42.9852 275.916 46.2C273.884 49.363 270.992 51.8 267.239 53.5111C263.487 55.1704 259.11 56 254.107 56Z" fill="black" />
        <path d="M282.609 55.2222C282.192 55.2222 281.801 55.0667 281.436 54.7556C281.124 54.3926 280.967 54.0037 280.967 53.5889C280.967 53.2778 280.994 53.0444 281.046 52.8889L299.26 3.03334C299.781 1.52963 300.823 0.777777 302.386 0.777777H314.581C316.145 0.777777 317.187 1.52963 317.708 3.03334L335.922 52.8889C335.974 53.0444 336 53.2778 336 53.5889C336 54.0037 335.818 54.3926 335.453 54.7556C335.14 55.0667 334.775 55.2222 334.358 55.2222H324.196C322.945 55.2222 322.059 54.6519 321.538 53.5111L318.724 45.9667H298.243L295.429 53.5111C294.908 54.6519 294.022 55.2222 292.771 55.2222H282.609ZM301.526 34.3H315.441L308.484 14.4667L301.526 34.3Z" fill="black" />
      </svg>
    )}
    {size === 'large' && (
      <svg width="336" height="56" viewBox="0 0 336 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.8422 56C18.7871 56 14.4877 55.2741 10.944 53.8222C7.40021 52.3704 4.71633 50.4519 2.89233 48.0667C1.06834 45.6815 0.104228 43.0889 0 40.2889C0 39.8222 0.156342 39.4333 0.469027 39.1222C0.833826 38.8111 1.25074 38.6556 1.71977 38.6556H12.1165C12.7419 38.6556 13.237 38.7593 13.6018 38.9667C14.0187 39.1741 14.4617 39.537 14.9307 40.0556C15.6082 41.3 16.6505 42.337 18.0575 43.1667C19.5167 43.9444 21.445 44.3333 23.8422 44.3333C26.6564 44.3333 28.8191 43.9185 30.3304 43.0889C31.8417 42.2074 32.5974 41.0148 32.5974 39.5111C32.5974 38.4222 32.2065 37.5407 31.4248 36.8667C30.6431 36.1407 29.3924 35.5185 27.6726 35C25.9528 34.4296 23.4774 33.8074 20.2463 33.1333C13.9405 31.8889 9.2242 29.9963 6.09735 27.4556C2.97051 24.9148 1.40708 21.2852 1.40708 16.5667C1.40708 13.3519 2.29302 10.5 4.0649 8.01111C5.8889 5.47037 8.44249 3.5 11.7257 2.1C15.061 0.7 18.9174 0 23.295 0C27.8811 0 31.8417 0.803704 35.177 2.41111C38.5645 3.96667 41.1441 5.93704 42.916 8.32222C44.74 10.6556 45.7041 12.963 45.8083 15.2444C45.8083 15.7111 45.652 16.1259 45.3393 16.4889C45.0266 16.8 44.6358 16.9556 44.1667 16.9556H33.2228C32.0763 16.9556 31.1903 16.4889 30.5649 15.5556C30.2523 14.4667 29.4445 13.5593 28.1416 12.8333C26.8388 12.0556 25.2232 11.6667 23.295 11.6667C21.1062 11.6667 19.4125 12.0556 18.2139 12.8333C17.0153 13.6111 16.416 14.7519 16.416 16.2556C16.416 17.7593 17.1977 18.9519 18.7611 19.8333C20.3245 20.663 23.2429 21.5185 27.5163 22.4C32.415 23.2815 36.2975 24.3963 39.1638 25.7444C42.0822 27.0407 44.2188 28.7519 45.5738 30.8778C46.9809 32.9519 47.6844 35.5963 47.6844 38.8111C47.6844 42.337 46.6682 45.3963 44.6358 47.9889C42.6033 50.5815 39.7891 52.5778 36.1933 53.9778C32.5974 55.3259 28.4804 56 23.8422 56Z" fill="black" />
        <path d="M68.1107 55.2222C67.5896 55.2222 67.1206 55.0407 66.7037 54.6778C66.3389 54.263 66.1564 53.7963 66.1564 53.2778V36.7111L48.0989 3.26667C47.9426 3.00741 47.8644 2.72222 47.8644 2.41111C47.8644 1.9963 48.0207 1.63333 48.3334 1.32222C48.6982 0.959259 49.0891 0.777777 49.506 0.777777H59.8246C60.5542 0.777777 61.1535 0.959259 61.6225 1.32222C62.0915 1.68519 62.4303 2.07407 62.6387 2.48889L73.5045 22.0111L84.3703 2.48889C84.5788 2.07407 84.9175 1.68519 85.3866 1.32222C85.8556 0.959259 86.4549 0.777777 87.1845 0.777777H97.5031C97.92 0.777777 98.2848 0.959259 98.5975 1.32222C98.9623 1.63333 99.1447 1.9963 99.1447 2.41111C99.1447 2.72222 99.0665 3.00741 98.9102 3.26667L80.8526 36.7111V53.2778C80.8526 53.8481 80.6442 54.3148 80.2273 54.6778C79.8625 55.0407 79.3934 55.2222 78.8202 55.2222H68.1107Z" fill="black" />
        <path d="M106.663 55.2222C106.142 55.2222 105.673 55.0407 105.256 54.6778C104.891 54.263 104.709 53.7963 104.709 53.2778V2.8C104.709 2.22963 104.891 1.76296 105.256 1.4C105.621 0.985185 106.09 0.777777 106.663 0.777777H115.496C116.695 0.777777 117.581 1.27037 118.154 2.25555L136.603 31.2667V2.8C136.603 2.22963 136.785 1.76296 137.15 1.4C137.515 0.985185 137.984 0.777777 138.557 0.777777H148.485C149.058 0.777777 149.527 0.985185 149.892 1.4C150.256 1.76296 150.439 2.22963 150.439 2.8V53.2C150.439 53.7704 150.256 54.263 149.892 54.6778C149.527 55.0407 149.058 55.2222 148.485 55.2222H139.651C138.453 55.2222 137.567 54.7296 136.993 53.7444L118.623 25.9V53.2778C118.623 53.8481 118.415 54.3148 117.998 54.6778C117.633 55.0407 117.164 55.2222 116.591 55.2222H106.663Z" fill="black" />
        <path d="M162.187 55.2222C161.666 55.2222 161.197 55.0407 160.78 54.6778C160.415 54.263 160.233 53.7963 160.233 53.2778V2.72222C160.233 2.15185 160.415 1.68519 160.78 1.32222C161.197 0.959259 161.666 0.777777 162.187 0.777777H173.131C173.704 0.777777 174.173 0.959259 174.538 1.32222C174.903 1.68519 175.085 2.15185 175.085 2.72222V43.0111H198.537C199.11 43.0111 199.579 43.2185 199.944 43.6333C200.361 43.9963 200.569 44.463 200.569 45.0333V53.2778C200.569 53.8481 200.361 54.3148 199.944 54.6778C199.579 55.0407 199.11 55.2222 198.537 55.2222H162.187Z" fill="black" />
        <path d="M208.245 55.2222C207.724 55.2222 207.255 55.0407 206.838 54.6778C206.473 54.263 206.291 53.7963 206.291 53.2778V2.72222C206.291 2.15185 206.473 1.68519 206.838 1.32222C207.255 0.959259 207.724 0.777777 208.245 0.777777H219.423C219.997 0.777777 220.466 0.959259 220.83 1.32222C221.195 1.68519 221.378 2.15185 221.378 2.72222V53.2778C221.378 53.7963 221.195 54.263 220.83 54.6778C220.466 55.0407 219.997 55.2222 219.423 55.2222H208.245Z" fill="black" />
        <path d="M254.107 56C246.55 56 240.635 54.1852 236.362 50.5556C232.088 46.8741 229.822 41.637 229.561 34.8444C229.509 33.3926 229.483 31.0333 229.483 27.7667C229.483 24.5 229.509 22.1148 229.561 20.6111C229.769 14.0778 232.036 9.02222 236.362 5.44445C240.739 1.81482 246.654 0 254.107 0C259.11 0 263.461 0.803704 267.161 2.41111C270.914 4.01852 273.754 6.09259 275.682 8.63333C277.662 11.1222 278.705 13.6889 278.809 16.3333C278.809 16.8 278.626 17.2148 278.262 17.5778C277.949 17.8889 277.558 18.0444 277.089 18.0444H264.973C264.451 18.0444 264.034 17.9667 263.722 17.8111C263.461 17.6037 263.201 17.3185 262.94 16.9556C262.315 15.5037 261.298 14.2593 259.891 13.2222C258.536 12.1852 256.608 11.6667 254.107 11.6667C248.061 11.6667 244.935 14.7778 244.726 21C244.674 22.4519 244.648 24.6815 244.648 27.6889C244.648 30.6963 244.674 32.9519 244.726 34.4556C244.935 41.0407 248.114 44.3333 254.263 44.3333C257.286 44.3333 259.683 43.5815 261.455 42.0778C263.227 40.5741 264.113 38.2926 264.113 35.2333V33.8333H257.155C256.582 33.8333 256.087 33.6519 255.67 33.2889C255.305 32.8741 255.123 32.3815 255.123 31.8111V25.7444C255.123 25.1741 255.305 24.7074 255.67 24.3444C256.087 23.9296 256.582 23.7222 257.155 23.7222H277.011C277.584 23.7222 278.053 23.9296 278.418 24.3444C278.835 24.7074 279.043 25.1741 279.043 25.7444V34.7667C279.043 39.1741 278.001 42.9852 275.916 46.2C273.884 49.363 270.992 51.8 267.239 53.5111C263.487 55.1704 259.11 56 254.107 56Z" fill="black" />
        <path d="M282.609 55.2222C282.192 55.2222 281.801 55.0667 281.436 54.7556C281.124 54.3926 280.967 54.0037 280.967 53.5889C280.967 53.2778 280.994 53.0444 281.046 52.8889L299.26 3.03334C299.781 1.52963 300.823 0.777777 302.386 0.777777H314.581C316.145 0.777777 317.187 1.52963 317.708 3.03334L335.922 52.8889C335.974 53.0444 336 53.2778 336 53.5889C336 54.0037 335.818 54.3926 335.453 54.7556C335.14 55.0667 334.775 55.2222 334.358 55.2222H324.196C322.945 55.2222 322.059 54.6519 321.538 53.5111L318.724 45.9667H298.243L295.429 53.5111C294.908 54.6519 294.022 55.2222 292.771 55.2222H282.609ZM301.526 34.3H315.441L308.484 14.4667L301.526 34.3Z" fill="black" />
      </svg>
    )}
    </>
  );
}
